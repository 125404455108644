import { Backdrop, Fade, Modal, withTheme } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { AdBanner } from '../../../store/AdminAds/types';

type ImageModalProps = {
  open: boolean;
  banner: AdBanner | null;
  handleClose: () => void;
};

const ImageModal: React.FC<ImageModalProps> = ({ open, banner, handleClose }) => {
  return (
    <StyledImageModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div style={{ outline: 'none' }}>
          {banner && (
            <img
              src={banner.imageURL}
              alt={banner.alt}
              style={{ width: banner.width, height: banner.height, maxWidth: '90vw', maxHeight: '90vh' }}
            />
          )}
        </div>
      </Fade>
    </StyledImageModal>
  );
};

export default ImageModal;

const StyledImageModal = withTheme(styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`);
