import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Loader from '../../Shared/Loading/Loader';
import { RouteComponentProps } from 'react-router';
import { doAppSubscribersOperations } from '../../../store/DoApp/Subscribers';
import { DoAppSubscriberVerificationStatus } from '../../../store/DoApp/Subscribers/types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import BtLLogoImage from '../../../assets/btl-logo-white-bg.png';

type DoAppSubscriberVerificationPublicPageProps = RouteComponentProps<{ verificationToken?: string }> & {};

const DoAppSubscriberVerificationPublicPage: FunctionComponent<DoAppSubscriberVerificationPublicPageProps> = ({
  match
}) => {
  const { t } = useTranslation();
  const [verificationStatus, setVerificationStatus] = useState<DoAppSubscriberVerificationStatus | null | 'error'>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const verifySubscriber = async () => {
      try {
        const verificationToken = match.params.verificationToken || '';
        const res = await doAppSubscribersOperations.verifySubscriber(verificationToken);
        setVerificationStatus(res.status);
      } catch (e) {
        setVerificationStatus('error');
      }

      setLoading(false);
    };

    verifySubscriber();
  }, []);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" borderTop="10px solid #000">
      <Box width="100%" bgcolor="#fff" display="flex" justifyContent="center">
        <img
          src={BtLLogoImage}
          alt="Be Truly Local"
          style={{
            width: '100px',
            height: '100px'
          }}
        />
      </Box>
      {loading && <Loader />}
      <Box p={3}>
        {!loading && verificationStatus === DoAppSubscriberVerificationStatus.VERIFIED && (
          <>
            <Alert severity="success" variant="outlined" style={{ background: '#fff' }}>
              <AlertTitle>{t('pages.doApp.subscriberVerification.statuses.verified.title')}</AlertTitle>
              {t('pages.doApp.subscriberVerification.statuses.verified.content')}
            </Alert>
          </>
        )}
        {!loading && verificationStatus === DoAppSubscriberVerificationStatus.EMAIL_CHANGED && (
          <>
            <Alert severity="success" variant="outlined" style={{ background: '#fff' }}>
              <AlertTitle>{t('pages.doApp.subscriberVerification.statuses.emailChanged.title')}</AlertTitle>
              {t('pages.doApp.subscriberVerification.statuses.emailChanged.content')}
            </Alert>
          </>
        )}
        {!loading &&
          verificationStatus === DoAppSubscriberVerificationStatus.EMAIL_CHANGE_WAITING_FOR_SECOND_CONFIRMATION && (
            <>
              <Alert severity="warning" variant="outlined" style={{ background: '#fff' }}>
                <AlertTitle>
                  {t('pages.doApp.subscriberVerification.statuses.emailChangeWaitingForSecondConfirmation.title')}
                </AlertTitle>
                {t('pages.doApp.subscriberVerification.statuses.emailChangeWaitingForSecondConfirmation.content')}
              </Alert>
            </>
          )}
        {!loading && verificationStatus === DoAppSubscriberVerificationStatus.DEVICE_REGISTERED && (
          <>
            <Alert severity="success" variant="outlined" style={{ background: '#fff' }}>
              <AlertTitle>{t('pages.doApp.subscriberVerification.statuses.deviceRegistered.title')}</AlertTitle>
              {t('pages.doApp.subscriberVerification.statuses.deviceRegistered.content')}
            </Alert>
          </>
        )}
        {!loading && verificationStatus === 'error' && (
          <>
            <Alert severity="error" variant="outlined" style={{ background: '#fff' }}>
              <AlertTitle>{t('pages.doApp.subscriberVerification.statuses.error.title')}</AlertTitle>
              {t('pages.doApp.subscriberVerification.statuses.error.content')}
            </Alert>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DoAppSubscriberVerificationPublicPage;
