import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import { AdBanner, RegionZonesAdsAssignments } from '../../../../../../store/AdminAds/types';
import { Alert } from '@material-ui/lab';
import ImageModal from '../../../../../Shared/ImageModal/ImageModal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {},
  district: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius
  },
  merchant: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius
  },
  banner: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius
  },
  searchField: {
    marginBottom: theme.spacing(2)
  },
  toggleButton: {
    marginLeft: theme.spacing(1)
  }
}));

type AdsAssignmentsTreeProps = {
  regionAdsAssigments: RegionZonesAdsAssignments[];
  merchant?: string;
};

const AdsAssignmentsTree: React.FC<AdsAssignmentsTreeProps> = ({ regionAdsAssigments, merchant = '' }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [activeBanner, setActiveBanner] = useState<AdBanner | null>(null);
  const [open, setOpen] = useState(false);
  const [expandedDistricts, setExpandedDistricts] = useState<Record<string, boolean>>({});
  const [searchTerm, setSearchTerm] = useState(merchant);

  useEffect(() => {
    // Initialize expandedDistricts with all districts expanded
    const initialExpanded = regionAdsAssigments.reduce((acc, district) => {
      acc[district.zoneId] = true;
      return acc;
    }, {} as Record<string, boolean>);
    setExpandedDistricts(initialExpanded);
  }, [regionAdsAssigments]);

  const handleOpen = (banner: AdBanner) => {
    setActiveBanner(banner);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleExpand = (zoneId: string) => {
    setExpandedDistricts((prev) => ({
      ...prev,
      [zoneId]: !prev[zoneId]
    }));
  };

  const filteredDistricts = regionAdsAssigments
    .map((district) => {
      // Check if the district matches by `zoneName` or `comments`
      const districtMatches =
        district.comments.toLowerCase().includes(searchTerm.toLowerCase()) ||
        district.zoneName.toLowerCase().includes(searchTerm.toLowerCase());

      // Filter advertisers that match the search term
      const filteredAdvertisers = district.advertisers.filter(
        (advertiser) =>
          advertiser.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          advertiser.banners.some((banner) => banner.description.toLowerCase().includes(searchTerm.toLowerCase()))
      );

      // Include the district if it matches by `zoneName` or `comments`,
      // or if there are matching advertisers
      if (districtMatches || filteredAdvertisers.length > 0) {
        return {
          ...district,
          advertisers: districtMatches ? district.advertisers : filteredAdvertisers
        };
      }

      return null; // Exclude districts without any match
    })
    .filter(Boolean); // Remove null values

  return (
    <Box className={classes.container}>
      <TextField
        className={classes.searchField}
        label={t('common.search')}
        variant="outlined"
        size="small"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: searchTerm !== '' && (
            <IconButton
              color={'primary'}
              size="small"
              onClick={() => {
                setSearchTerm('');
              }}
            >
              <ClearIcon />
            </IconButton>
          )
        }}
      />
      {filteredDistricts.map(
        (district) =>
          district && (
            <Paper key={district.zoneId} className={classes.district} elevation={1}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <div>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {district.comments} ({district.width}x{district.height})
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    {district.zoneName}
                  </Typography>
                </div>
                <IconButton className={classes.toggleButton} onClick={() => toggleExpand(district.zoneId)}>
                  {expandedDistricts[district.zoneId] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              {expandedDistricts[district.zoneId] && (
                <>
                  {district.advertisers.length === 0 && (
                    <Alert severity="info" variant="outlined" style={{ padding: '0px 6px' }}>
                      {t('pages.adminOrganizations.windows.noMerchants')}
                    </Alert>
                  )}
                  {district.advertisers.map((advertiser) => (
                    <Box key={advertiser.id} className={classes.merchant}>
                      <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>
                        {advertiser.name}
                      </Typography>
                      {advertiser.banners.length === 0 && (
                        <Alert severity="info" variant="outlined" style={{ padding: '0px 6px' }}>
                          {t('pages.adminOrganizations.windows.noAds')}
                        </Alert>
                      )}
                      {advertiser.banners.map((banner) => (
                        <Box key={banner.bannerId} className={classes.banner}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              src={banner.imageURL}
                              alt={banner.alt}
                              style={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'contain',
                                objectPosition: 'center',
                                border: '1px solid #E5E5E5',
                                cursor: 'pointer'
                              }}
                              onClick={() => handleOpen(banner)}
                            />
                            <div style={{ flexGrow: 1, marginLeft: 10 }}>
                              {banner.description}
                              <br />
                              <a target="_blank" rel="noopener noreferrer" href={banner.url}>
                                {banner.url}
                              </a>
                            </div>
                          </div>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </>
              )}
            </Paper>
          )
      )}
      <ImageModal banner={activeBanner} handleClose={handleClose} open={open} />
    </Box>
  );
};

export default AdsAssignmentsTree;
