import { BaseSettings, RequestSettings } from '../../utils/httpRequest';
import {
  AdBannerType,
  AdminCreateAdBannerValues,
  AdminCreateAdCategoryValues,
  AdminUpdateAdBannerValues
} from './types';

export const updateAdBanner = (
  settings: RequestSettings<{ bannerId: string; type: AdBannerType }, AdminUpdateAdBannerValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/revenue/:type/designs/:bannerId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteAdBanner = (settings: RequestSettings<{ bannerId: string; type: AdBannerType }>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/revenue/:type/designs/:bannerId'
  },
  params: settings.requestParams
});

export const getZones = (settings: RequestSettings<{ sizes: string; advertiserId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/utilities/zones/:advertiserId?sizes=:sizes'
  },
  params: settings.requestParams
});

export const getAdZones = (
  settings: RequestSettings<{ region: string; districtCode: string; size?: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/organizations/do_app/:region/:districtCode/digital_zones?size=:size'
  },
  params: settings.requestParams
});

export const getZonesByRegionAccountNumber = (
  settings: RequestSettings<{ regionAccountNumber: string; size: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/utilities/region_zones/:regionAccountNumber?size=:size'
  },
  params: settings.requestParams
});

export const getRegionZonesAdsAssignments = (
  settings: RequestSettings<{ regionAccountNumber: string; size?: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/utilities/region_zones_with_assignments/:regionAccountNumber?size=:size'
  },
  params: settings.requestParams
});

export const createAdBanner = (
  settings: RequestSettings<{ type: AdBannerType }, AdminCreateAdBannerValues>
): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/revenue/:type/designs?linkToZoneId=:linkToZoneId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const indexOrganizationBanners = (settings: RequestSettings<{ organizationId: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/email/designs/:organizationId'
  },
  params: settings.requestParams
});

export const indexHouseAds = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/email/house_ads'
  }
});

export const getChartData = (settings: RequestSettings<{ startDate: string; endDate: string }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/utilities/designs/chart/:startDate/:endDate'
  },
  params: settings.requestParams
});

export const getAdvertiserStatistics = (
  settings: RequestSettings<{ startDate: string; endDate: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/utilities/designs/statistics/advertiser/:startDate/:endDate'
  },
  params: settings.requestParams
});

export const getAdvertiserBannerStatistics = (
  settings: RequestSettings<{ advertiserId: number; startDate: string; endDate: string }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/utilities/designs/statistics/advertiser/:advertiserId/banner/:startDate/:endDate'
  },
  params: settings.requestParams
});

export const createAdCategory = (settings: RequestSettings<{}, AdminCreateAdCategoryValues>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/api/v1/admin/revenue/email/designs/design_category'
  },
  payload: settings.requestPayload
});

export const updateAdCategory = (
  settings: RequestSettings<{ id: string }, AdminCreateAdCategoryValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/revenue/email/designs/design_category/:id'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const removeAdCategory = (settings: RequestSettings<{ id: string }, {}>): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/revenue/email/designs/design_category/:id'
  },
  params: settings.requestParams
});

export const indexHouseAdsCategories = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/email/designs/design_category'
  }
});

export const updateMobileCampaign = (
  settings: RequestSettings<{ bannerId: string; type: AdBannerType }, AdminUpdateAdBannerValues>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/revenue/:type/designs/:bannerId'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});

export const deleteMobileCampaign = (
  settings: RequestSettings<{ bannerId: string; type: AdBannerType }>
): BaseSettings => ({
  query: {
    method: 'delete',
    url: '/api/v1/admin/revenue/:type/designs/:bannerId'
  },
  params: settings.requestParams
});

export const indexAdvertiserBanners = (
  settings: RequestSettings<{ advertiserId: string; type: AdBannerType }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/:type/designs/:advertiserId'
  },
  params: settings.requestParams
});

export const showBanner = (settings: RequestSettings<{ bannerId: string; type: AdBannerType }, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/revenue/:type/design/:bannerId'
  },
  params: settings.requestParams
});

export const linkBannerToZones = (
  settings: RequestSettings<{ bannerId: string }, { zoneIds: string[]; allZones: boolean }>
): BaseSettings => ({
  query: {
    method: 'put',
    url: '/api/v1/admin/revenue/utilities/designs/:bannerId/link_to_zones'
  },
  payload: settings.requestPayload,
  params: settings.requestParams
});
