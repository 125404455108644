import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppMerchant } from '../../../../../store/DoApp/Merchants/types';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import DoAppInvoicePreviewWindow from '../windows/DoAppInvoicePreview';
import Toast from '../../../../Shared/Toast/Toast';
import DoAppAddIncentiveCredits from '../windows/DoAppAddIncentiveCredits';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { Strong } from '../../../../Shared/StyledComponents';
import HistoryIcon from '@material-ui/icons/History';
import MerchantRewardsWindow from '../../../MyOrganization/Windows/MerchantRewardsWindow';

type DoAppMerchantsListProps = {};

const DoAppMerchantsList: FunctionComponent<DoAppMerchantsListProps> = () => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [merchants, setMerchants] = useState<DoAppMerchant[]>([]);
  const [activeMerchant, setActiveMerchant] = useState<DoAppMerchant | null>(null);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });
  const [invoicePreviewWindowOpen, onInvoicePreviewWindowOpen, onInvoicePreviewWindowClose] = useOpenHandler();
  const [incentiveCreditsWindowOpen, onIncentiveCreditsWindowOpen, onIncentiveCreditsWindowClose] = useOpenHandler();
  const [merchantRewardsWindowOpen, onMerchantRewardsWindowOpen, onMerchantRewardsWindowClose] = useOpenHandler();

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const merchants = await doAppMerchantsOperations.indexRaw(page, 10, search, {});
      setMerchants(merchants.data);
      setPagination(merchants.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const handleSubmitCreditsAdd = async (newCredits: number, note?: string) => {
    if (activeMerchant) {
      try {
        await doAppMerchantsOperations.addIncentiveCredits(activeMerchant.id, newCredits, note);
        onIncentiveCreditsWindowClose();
        fetchData(pagination.page, searchValue);
        Toast.success(t('pages.doApp.merchants.addSuccess'));
      } catch (error) {
        Toast.error(t('pages.doApp.merchants.addError'));
        console.error('Error adding incentive credits:', error);
      }
    }
  };

  const renderList = () => {
    return (
      <>
        <WidgetTitle title={t('pages.doApp.merchants.title')} subtitle={t('pages.doApp.merchants.subtitle')} />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />

        {fetchError && <WidgetFetchError title={t('pages.doApp.merchants.fetchError')} />}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('pages.doApp.merchants.merchant')}</TableCell>
                <TableCell>{t('pages.doApp.merchants.id')}</TableCell>
                <TableCell align="right">{t('pages.doApp.merchants.incentiveCredits')}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchants.map((merchant) => (
                <TableRow key={merchant.id}>
                  <TableCell component="th" scope="row">
                    <Strong>{merchant.name}</Strong>
                    <Box display="flex" alignItems="center">
                      <MailOutlineOutlinedIcon style={{ width: 14, height: 14, marginRight: 4 }} />{' '}
                      {merchant.billingEmail}
                    </Box>
                  </TableCell>
                  <TableCell>{merchant.merchantPortalId}</TableCell>
                  <TableCell align="right">
                    ${(merchant.incentiveCredits / 100).toFixed(2)}{' '}
                    {merchant.incentiveCredits > 0 && (
                      <IconButton
                        size="small"
                        disableRipple
                        onClick={() => {
                          setActiveMerchant(merchant);
                          onMerchantRewardsWindowOpen();
                        }}
                      >
                        <HistoryIcon fontSize="inherit" />
                      </IconButton>
                    )}
                    <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        setActiveMerchant(merchant);
                        onIncentiveCreditsWindowOpen();
                      }}
                      style={{ marginLeft: 5 }}
                      startIcon={<AddOutlinedIcon />}
                    >
                      {t('pages.doApp.merchants.add')}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={async () => {
                        setActiveMerchant(merchant);
                        onInvoicePreviewWindowOpen();
                      }}
                      startIcon={<ReceiptOutlinedIcon />}
                    >
                      {t('pages.doApp.merchants.invoicePreview')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>
        <DoAppInvoicePreviewWindow
          open={invoicePreviewWindowOpen}
          merchant={activeMerchant}
          onCloseClick={onInvoicePreviewWindowClose}
          fullScreenOnMobile
        />
        {activeMerchant && (
          <DoAppAddIncentiveCredits
            open={incentiveCreditsWindowOpen}
            merchant={activeMerchant}
            onCloseClick={onIncentiveCreditsWindowClose}
            onSubmit={handleSubmitCreditsAdd}
          />
        )}
        {activeMerchant && merchantRewardsWindowOpen && (
          <MerchantRewardsWindow
            open={merchantRewardsWindowOpen}
            onCloseClick={onMerchantRewardsWindowClose}
            fullScreenOnMobile
            type="incentive"
            organizationMerchant={activeMerchant}
            removeIncentiveCredits
            onRemoveIncentiveCredits={() => fetchData(pagination.page, searchValue)}
          />
        )}
      </>
    );
  };

  return renderList();
};

export default DoAppMerchantsList;
