import { createOrderArray, httpRequest } from '../../../utils';
import { DoAppSubscribersIndexResponse, DoAppSubscriberVerificationStatus } from './types';
import { doAppSubscribersActions, doAppSubscribersRequests } from './index';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../rootReducer';

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppSubscribersIndexResponse>(
    doAppSubscribersRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const indexMerchantSubscribers = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { doAppMerchantSubscribers } = getState();

  try {
    dispatch(doAppSubscribersActions.doAppSubscribersIndexBegin());

    const response = await indexRaw(
      doAppMerchantSubscribers.pagination.page,
      doAppMerchantSubscribers.pagination.per,
      doAppMerchantSubscribers.pagination.q,
      doAppMerchantSubscribers.tableSearch.order
    );

    dispatch(doAppSubscribersActions.doAppSubscribersIndexSuccess(response));
  } catch (e) {
    dispatch(doAppSubscribersActions.doAppSubscribersIndexFailed(e));
  }
};

export const updateCredits = (id: string, credits: number) => {
  return httpRequest.runRequest(
    doAppSubscribersRequests.updateCredits({
      requestParams: { id },
      requestPayload: { credits }
    })
  );
};

export const verifySubscriber = (verificationToken: string) => {
  return httpRequest.runRequest<{ status: DoAppSubscriberVerificationStatus }>(
    doAppSubscribersRequests.verifySubscriber({
      requestParams: { verificationToken }
    })
  );
};
