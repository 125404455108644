export type DoAppSponsor = {
  id: string;
  email: string;
  name: string;
  amount: number;
  availableAmount: number;
  amountToGive: number;
  regions: string[];
  poolTarget: SponsorPoolTarget;
  adZoneId: string;
  advertiserId: string;
};

export type DoAppSponsorsIndexResponse = {
  data: DoAppSponsor[];
  pagination: Pagination;
};

export type IndexDoAppSponsorsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export enum SponsorPoolTarget {
  MERCHANT = 'merchant',
  SUBSCRIBER = 'subscriber'
}

export type CreateSponsorRequestBodyDto = {
  email: string;
  name: string;
  amount: number;
  amountToGive: number;
  regions: string[];
  poolTarget: SponsorPoolTarget;
};
