import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../Shared/StyledComponents';
import Toast from '../../../Shared/Toast/Toast';
import { doAppMerchantsOperations } from '../../../../store/DoApp/Merchants';
import { DoAppMerchant, MerchantReward } from '../../../../store/DoApp/Merchants/types';

type DeleteIncentiveCreditsWindowProps = {
  open: boolean;
  organizationMerchant: DoAppMerchant;
  reward: MerchantReward;
  onCloseClick: () => void;
  onSubmit: () => void;
  fullScreenOnMobile: boolean;
};

const DeleteIncentiveCreditsWindow: FunctionComponent<DeleteIncentiveCreditsWindowProps> = ({
  onCloseClick,
  onSubmit,
  organizationMerchant,
  reward,
  open
}) => {
  const { t } = useTranslation();

  const onFormSubmit = async () => {
    try {
      if (organizationMerchant) {
        await doAppMerchantsOperations.deleteIncentiveCredits(organizationMerchant.id, reward.id);
        onSubmit();
        onCloseClick();
        Toast.success(t('pages.myOrganization.windows.deleteIncentiveCredits.incentiveCreditsDeleted'));
      }
    } catch (error) {
      Toast.error(t('pages.myOrganization.windows.deleteIncentiveCredits.deleteIncentiveCreditsError'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.deleteIncentiveCredits.title')}
      onCloseClick={onCloseClick}
      onSubmit={onFormSubmit}
    >
      <Row>
        <Strong>
          {t('pages.myOrganization.windows.deleteIncentiveCredits.deleteIncentiveCreditsConfirmation', {
            amount: (reward.amount / 100).toFixed(2),
            merchantName: organizationMerchant.name
          })}
        </Strong>
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DeleteIncentiveCreditsWindow;
