import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOrganization, OrganizationAdZone } from '../../../../../store/AdminOrganizations/types';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import Toast from '../../../../Shared/Toast/Toast';
import Loader from '../../../../Shared/Loading/Loader';
import { Button } from '@material-ui/core';
import { adminAdsOperations } from '../../../../../store/AdminAds';

type AdminShowOrganizationAdZonesWindowProps = {
  open: boolean;
  organization: AdminOrganization | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminShowOrganizationAdZonesWindow: FunctionComponent<AdminShowOrganizationAdZonesWindowProps> = ({
  organization,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [zones, setZones] = React.useState<OrganizationAdZone[]>([]);

  const fetchZones = async () => {
    setZones([]);
    setLoading(true);
    try {
      if (open && organization && organization.region && organization.district) {
        const response = await adminAdsOperations.getAdZones(organization.region, organization.district);
        setZones(response.data);
      }
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchZones();
  }, [open, organization]);

  if (!organization) return null;

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminOrganizations.windows.adZonesTitle')}
      okButtonText={t('common.close')}
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      {loading && <Loader />}
      <Row>{t('pages.adminOrganizations.windows.adZonesContent')}</Row>
      {zones.map((zone) => (
        <Row key={zone.id}>
          <div>
            <Strong>{zone.size}</Strong>{' '}
            <Button
              variant="contained"
              color="secondary"
              disabled={!zone.adZoneLink}
              onClick={() => window.open(`${zone.adZoneLink}&log=no`, '_blank')}
            >
              {t('common.open')}
            </Button>
          </div>
        </Row>
      ))}
    </ModalConfirmationWindow>
  );
};

export default AdminShowOrganizationAdZonesWindow;
