import { ApplicationRoute } from '../../../typings';
import linksConstants from '../../../config/app/linksConstants';
import DoAppSubscriberVerificationPublicPage from './DoAppSubscriberVerificationPublicPage';

const DoAppSubscriberVerificationRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.DOAPP_SUBSCRIBER_VERIFICATION(':verificationToken'),
    exact: false,
    name: 'doAppSubscriberVerificationPublic',
    public: true,
    show: true,
    component: DoAppSubscriberVerificationPublicPage,
    scope: []
  }
];

export default DoAppSubscriberVerificationRoutes;
