import React, { FunctionComponent, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LpiLogoImage from '../../../assets/epub-logo-white.png';
import NhmLogoImage from '../../../assets/story-creator-logo-white.png';
import BtLLogoImage from '../../../assets/btl-story-creator-logo-white.png';
import { Divider, withTheme } from '@material-ui/core';
import { useTypedSelector } from '../../../utils';
import linksConstants from '../../../config/app/linksConstants';
import { useTranslation } from 'react-i18next';
import PinDropIcon from '@material-ui/icons/PinDrop';
import * as pkg from '../../../../package.json';
import {
  hasPermission,
  hasPermissionToAnyAdministration,
  hasPermissionToAnyChannel,
  hasPermissionToSystemReports,
  isSystemOwner
} from '../../../utils/permissions';
import { ReactComponent as DashboardIcon } from '../../../assets/icons/navigation/dashboard.svg';
import { ReactComponent as StoriesIcon } from '../../../assets/icons/navigation/stories.svg';
import { ReactComponent as PrintIcon } from '../../../assets/icons/navigation/print.svg';
import { ReactComponent as AdminDashboardIcon } from '../../../assets/icons/navigation/admin-dashboard.svg';
import { ReactComponent as AdsStatisticsIcon } from '../../../assets/icons/navigation/ads-statistics.svg';
import { ReactComponent as EmailTemplatesIcon } from '../../../assets/icons/navigation/email-templates.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/navigation/email.svg';
import { ReactComponent as HouseAdsIcon } from '../../../assets/icons/navigation/house-ads.svg';
import { ReactComponent as KeywordsIcon } from '../../../assets/icons/navigation/keywords.svg';
import { ReactComponent as MailingListsDashboardIcon } from '../../../assets/icons/navigation/mailing-lists-dashboard.svg';
import { ReactComponent as MailingListsIcon } from '../../../assets/icons/navigation/mailing-lists.svg';
import { ReactComponent as MyAccountIcon } from '../../../assets/icons/navigation/my-account.svg';
import { ReactComponent as MyOrganizationIcon } from '../../../assets/icons/navigation/my-organization.svg';
import { ReactComponent as OrganizationsIcon } from '../../../assets/icons/navigation/organizations.svg';
import { ReactComponent as PasswordPoliciesIcon } from '../../../assets/icons/navigation/password-policies.svg';
import { ReactComponent as PrintTemplatesIcon } from '../../../assets/icons/navigation/print-templates.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/icons/navigation/reports.svg';
import { ReactComponent as SignOutIcon } from '../../../assets/icons/navigation/sign-out.svg';
import { ReactComponent as SupportIcon } from '../../../assets/icons/navigation/support.svg';
import { ReactComponent as SystemSettingsIcon } from '../../../assets/icons/navigation/system-settings.svg';
import { ReactComponent as UserRolesIcon } from '../../../assets/icons/navigation/user-roles.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/navigation/users.svg';
import { ReactComponent as SharingCenterIcon } from '../../../assets/icons/navigation/sharing-center.svg';
import { ReactComponent as CampaignsIcon } from '../../../assets/icons/navigation/campaigns.svg';
import { ReactComponent as DesignProjectsIcon } from '../../../assets/icons/navigation/design-projects.svg';
import IconButton from '@material-ui/core/IconButton';
import CalendarIcon from '@material-ui/icons/Event';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { APP_THEME } from '../../../typings/environment';
import { isTheme } from '../../../utils/environment';
import { DefaultRoleName } from '../../../store/AdminRoles/types';

type SidebarProps = {
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  collapsed: boolean;
  hasIcons?: boolean;
  canCollapse?: boolean;
};

const Sidebar: FunctionComponent<SidebarProps> = ({
  setCollapsed,
  collapsed,
  hasIcons = false,
  canCollapse = false
}) => {
  const { t } = useTranslation();
  const organization = useTypedSelector((state) => state.auth.organization);
  const authState = useTypedSelector((state) => state.auth);
  const role = useTypedSelector((state) => state.auth.role);
  const user = useTypedSelector((state) => state.auth.user);
  const version = useTypedSelector((state) => state.auth.version);

  const handleSidebarOpen = () => {
    setCollapsed(false);
    localStorage.setItem('sc-sidebar-collapsed', 'false');
  };

  const handleSidebarClose = () => {
    setCollapsed(true);
    localStorage.setItem('sc-sidebar-collapsed', 'true');
  };

  const iconStyle = {
    width: '20px',
    height: '20px',
    fill: '#fff',
    opacity: 0.5,
    verticalAlign: 'middle',
    marginRight: '5px'
  };

  const getThemeLogo = () => {
    if (isTheme(APP_THEME.NHM)) {
      return <Logo src={NhmLogoImage} />;
    } else if (isTheme(APP_THEME.BTL)) {
      return <Logo src={BtLLogoImage} />;
    } else {
      return <Logo src={LpiLogoImage} />;
    }
  };

  return (
    <SidebarContainer>
      {collapsed ? (
        <div style={{ padding: '2rem 0 1rem 1rem' }}>
          <IconButton onClick={handleSidebarOpen}>
            <MenuIcon htmlColor="#fff" />
          </IconButton>
        </div>
      ) : (
        <LogoWrapper>
          {getThemeLogo()}
          {canCollapse && (
            <IconButton onClick={handleSidebarClose}>
              <ChevronLeftIcon htmlColor="#fff" />
            </IconButton>
          )}
        </LogoWrapper>
      )}

      <NavigationContainer collapsed={collapsed}>
        <MainNavigation>
          {user.type.name !== DefaultRoleName.CONTENT_CONTRIBUTOR && (
            <NavigationItem to={linksConstants.DASHBOARD.INDEX}>
              {hasIcons && <DashboardIcon style={iconStyle} />}
              {!collapsed && t('navigation.dashboard')}
            </NavigationItem>
          )}
          <NavigationItem to={linksConstants.CALENDAR}>
            {hasIcons && <CalendarIcon style={iconStyle} />}
            {!collapsed && t('navigation.calendar')}
          </NavigationItem>
          {hasPermissionToAnyChannel(role) && organization && organization.enableCampaigns && (
            <NavigationItem to={linksConstants.CAMPAIGNS.INDEX}>
              {hasIcons && <CampaignsIcon style={iconStyle} />}
              {!collapsed && t('navigation.campaigns')}
            </NavigationItem>
          )}

          {hasPermissionToAnyChannel(role) && (
            <>
              <Title>{collapsed ? <NavDivider /> : t('navigation.projects')}</Title>
              {hasPermission(role, ['storiesAccess']) && (
                <NavigationItem to={linksConstants.STORY.INDEX}>
                  {hasIcons && <StoriesIcon style={iconStyle} />}
                  {!collapsed && t('navigation.stories')}
                </NavigationItem>
              )}
              {hasPermission(role, ['storiesAccess']) && (
                <NavigationItem to={linksConstants.DASHBOARD.DESIGN_PROJECTS}>
                  {hasIcons && <DesignProjectsIcon style={iconStyle} />}
                  {!collapsed && t('navigation.designProjects')}
                </NavigationItem>
              )}
              {hasPermission(role, ['projectsPrintAccess']) && (
                <NavigationItem to={linksConstants.DASHBOARD.PRINT_PROJECTS}>
                  {hasIcons && <PrintIcon style={iconStyle} />}
                  {!collapsed && t('navigation.designerProjects')}
                </NavigationItem>
              )}
              {hasPermission(role, ['projectsEmailAccess']) && (
                <NavigationItem to={linksConstants.DASHBOARD.EMAIL_PROJECTS}>
                  {hasIcons && <EmailIcon style={iconStyle} />}
                  {!collapsed && t('navigation.emailPublishing')}
                </NavigationItem>
              )}
              {organization && organization.masterOrgId && (
                <NavigationItem to={linksConstants.DASHBOARD.SHARING_CENTER}>
                  {hasIcons && <SharingCenterIcon style={iconStyle} />}
                  {!collapsed && t('navigation.sharingCenter')}
                </NavigationItem>
              )}
            </>
          )}

          {hasPermissionToAnyAdministration(role) && (
            <>
              <Title>{collapsed ? <NavDivider /> : t('navigation.administration')}</Title>

              {isSystemOwner(authState) && (
                <NavigationItem to={linksConstants.DO_APP.DASHBOARD}>
                  {hasIcons && <PinDropIcon style={iconStyle} />}
                  {!collapsed && t('navigation.doapp')}
                </NavigationItem>
              )}
              {hasPermission(role, ['mailingListsView']) && (
                <NavigationItem to={linksConstants.MAILING_LISTS.INDEX}>
                  {hasIcons && <MailingListsIcon style={iconStyle} />}
                  {!collapsed && t('navigation.mailingLists')}
                </NavigationItem>
              )}
              {hasPermission(role, ['organizationsView', 'adsView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX}>
                  {hasIcons && <OrganizationsIcon style={iconStyle} />}
                  {!collapsed && t('navigation.organizations')}
                </NavigationItem>
              )}
              {hasPermission(role, ['usersView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.USERS.INDEX}>
                  {hasIcons && <UsersIcon style={iconStyle} />}
                  {!collapsed && t('navigation.users')}
                </NavigationItem>
              )}
              {hasPermission(role, ['rolesView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.ROLES.INDEX}>
                  {hasIcons && <UserRolesIcon style={iconStyle} />}
                  {!collapsed && t('navigation.roles')}
                </NavigationItem>
              )}
              {hasPermission(role, ['keywordsView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.KEYWORDS.INDEX}>
                  {hasIcons && <KeywordsIcon style={iconStyle} />}
                  {!collapsed && t('navigation.keywords')}
                </NavigationItem>
              )}
              {hasPermission(role, ['templatesView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.EMAIL_TEMPLATES.INDEX}>
                  {hasIcons && <EmailTemplatesIcon style={iconStyle} />}
                  {!collapsed && t('navigation.emailTemplates')}
                </NavigationItem>
              )}
              {hasPermission(role, ['templatesView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.PRINT_TEMPLATES.INDEX}>
                  {hasIcons && <PrintTemplatesIcon style={iconStyle} />}
                  {!collapsed && t('navigation.printTemplates')}
                </NavigationItem>
              )}
              {hasPermission(role, ['systemSettingsView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.SYSTEM_SETTINGS.INDEX}>
                  {hasIcons && <SystemSettingsIcon style={iconStyle} />}
                  {!collapsed && t('navigation.systemSettings')}
                </NavigationItem>
              )}
              {hasPermission(role, ['passwordPoliciesView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.PASSWORD_POLICIES.INDEX}>
                  {hasIcons && <PasswordPoliciesIcon style={iconStyle} />}
                  {!collapsed && t('navigation.passwordPolicies')}
                </NavigationItem>
              )}
              {hasPermission(role, ['adsView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.ADS.INDEX}>
                  {hasIcons && <HouseAdsIcon style={iconStyle} />}
                  {!collapsed && t('navigation.adsAdministration')}
                </NavigationItem>
              )}
              {hasPermissionToSystemReports(role) && (
                <NavigationItem to={linksConstants.ADMIN_REPORTS.INDEX}>
                  {hasIcons && <ReportsIcon style={iconStyle} />}
                  {!collapsed && t('navigation.adminReportsDashboard')}
                </NavigationItem>
              )}
              {hasPermission(role, ['adsView']) && (
                <NavigationItem to={linksConstants.ADS_STATISTICS}>
                  {hasIcons && <AdsStatisticsIcon style={iconStyle} />}
                  {!collapsed && t('navigation.adsStatistics')}
                </NavigationItem>
              )}
              {hasPermission(role, ['backofficeProjectsDashboard']) && (
                <NavigationItem to={linksConstants.ADMIN_PROJECTS}>
                  {hasIcons && <AdminDashboardIcon style={iconStyle} />}
                  {!collapsed && t('navigation.adminProjects')}
                </NavigationItem>
              )}
              {hasPermission(role, ['adminMailingListsView']) && (
                <NavigationItem to={linksConstants.ADMINISTRATION.MAILING_LISTS.INDEX}>
                  {hasIcons && <MailingListsDashboardIcon style={iconStyle} />}
                  {!collapsed && t('navigation.adminMailingLists')}
                </NavigationItem>
              )}
            </>
          )}
        </MainNavigation>

        <BottomNavigation>
          <NavigationItem data-suppressbottommargin to={linksConstants.MY_ORGANIZATION}>
            {hasIcons && <MyOrganizationIcon style={iconStyle} />}
            {!collapsed && t('navigation.myOrganization')}
          </NavigationItem>
          {!collapsed && <OrganizationName>{organization && organization.name}</OrganizationName>}

          <NavigationItem to={linksConstants.MY_ACCOUNT}>
            {hasIcons && <MyAccountIcon style={iconStyle} />}
            {!collapsed && t('navigation.myAccount')}
          </NavigationItem>
          <NavigationItem to={linksConstants.SUPPORT}>
            {hasIcons && <SupportIcon style={iconStyle} />}
            {!collapsed && t('navigation.support')}
          </NavigationItem>
          <NavigationItem to={linksConstants.AUTH.LOGOUT}>
            {hasIcons && <SignOutIcon style={iconStyle} />}
            {!collapsed && t('navigation.signOut')}
          </NavigationItem>
        </BottomNavigation>
      </NavigationContainer>
      {!collapsed && (
        <ApplicationInfo style={{ justifyContent: 'space-between' }}>
          <div>Powered by StoryCreator</div>
          <div>
            {pkg.version} | {version}
          </div>
        </ApplicationInfo>
      )}
    </SidebarContainer>
  );
};

type NavigationContainerProps = HTMLAttributes<HTMLDivElement> & { collapsed: boolean };

const SidebarContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
  grid-template-areas: 'logo' 'navigation';
  height: 100vh;
`;

const Logo = styled.img`
  max-width: 80%;
  max-height: 100%;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: logo;
  width: 100%;
  padding: 2rem 1.6rem 1rem 2rem;
`;

const NavigationContainer = styled.div<NavigationContainerProps>`
  grid-area: navigation;
  padding: ${(props) => (props.collapsed ? '2rem 0 0 1.9rem' : '2rem 2rem 0 2rem')};
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const MainNavigation = styled.div`
  flex-grow: 1;
`;
const BottomNavigation = styled.div`
  padding-bottom: 1rem;
`;
const NavDivider = styled(Divider)`
  width: 20px;
  background: #fff;
  opacity: 0.5;
`;
const Title = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.custom.navigation.fontColor};
  font-size: 0.9rem;
  font-weight: bold;
  margin: 2rem 0 1rem 0;
  text-transform: uppercase;
  letter-spacing: 1px;
`);
const OrganizationName = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.custom.navigation.fontColor};
  font-size: 0.6rem;
  margin-bottom: 1rem;
`);
const NavigationItem = withTheme(styled(Link)<{ 'data-suppressbottommargin': boolean }>`
  display: block;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
  margin-bottom: ${(props) => (props['data-suppressbottommargin'] ? 0 : '0.5rem')};
  outline: none;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`);

const ApplicationInfo = styled.div`
  font-size: 10px;
  opacity: 0.7;
  color: #fff;
  display: flex;
  justify-content: space-between;
`;

export default Sidebar;
