import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOrganization } from '../../../../../store/AdminOrganizations/types';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import Toast from '../../../../Shared/Toast/Toast';
import Loader from '../../../../Shared/Loading/Loader';
import { adminAdsOperations } from '../../../../../store/AdminAds';
import { RegionZonesAdsAssignments } from '../../../../../store/AdminAds/types';
import AdsAssignmentsTree from '../../Ads/Partials/AdsAssignmentsTree/AdsAssignmentsTree';

type AdminShowOrganizationAdsAssignmentsWindowProps = {
  open: boolean;
  organization: AdminOrganization | null;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
};

const AdminShowOrganizationAdsAssignmentsWindow: FunctionComponent<AdminShowOrganizationAdsAssignmentsWindowProps> = ({
  organization,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [regionAdsAssigments, setRegionAdsAssigments] = React.useState<RegionZonesAdsAssignments[]>([]);

  const fetchAdsAssignments = async () => {
    setLoading(true);
    try {
      if (open && organization) {
        const regionAccountNumber =
          [organization.accountNumber, organization.region].find((account) =>
            (account || '').startsWith('btl.region')
          ) || null;

        if (regionAccountNumber) {
          const { data } = await adminAdsOperations.getRegionZonesAdsAssignments(regionAccountNumber);
          setRegionAdsAssigments(data);
        }
      }
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdsAssignments();
  }, [open, organization]);

  if (!organization) return null;

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminOrganizations.windows.adsAssignmentsTitle')}
      okButtonText={t('common.close')}
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      {loading && <Loader />}
      {!loading && (
        <AdsAssignmentsTree
          regionAdsAssigments={regionAdsAssigments}
          merchant={(organization.accountNumber || '').startsWith('btl.region') ? '' : organization.name}
        />
      )}
    </ModalConfirmationWindow>
  );
};

export default AdminShowOrganizationAdsAssignmentsWindow;
