import { httpRequest } from '../../utils';
import {
  AdBanner,
  AdBannerType,
  AdCampaign,
  AdCategory,
  AdminCreateAdBannerValues,
  AdminCreateAdCategoryValues,
  AdminUpdateAdBannerValues,
  AdminUpdateAdCategoryValues,
  Advertiser,
  AdvertiserBannerStatistics,
  AdvertiserStatistics,
  AdZone,
  ChartDataByDays,
  RegionZonesAdsAssignments
} from './types';
import { adminAdsRequests } from './index';
import moment from 'moment';
import { AdminOrganization, OrganizationAdZone } from '../AdminOrganizations/types';
import { AdminEmailTemplate } from '../AdminEmailTemplates/types';

const prepareBannerFormData = (formData: FormData, values: AdminCreateAdBannerValues | AdminUpdateAdBannerValues) => {
  if (values.bannerFile) {
    formData.append('bannerFile', values.bannerFile);
  }

  formData.append('height', values.height);
  formData.append('width', values.width);
  formData.append('alt', values.alt);
  formData.append('comments', values.comments);
  formData.append('url', values.url);
  formData.append('bannerName', values.bannerName);
  formData.append('advertiserId', values.advertiserId);
  formData.append('startDate', moment(values.startDate).set({ hours: 0, minutes: 0, seconds: 0 }).format());
  if (values.endDate) {
    formData.append('endDate', moment(values.endDate).set({ hours: 23, minutes: 59, seconds: 59 }).format());
  }
  if (values.category && values.category.id) {
    formData.append('categoryId', values.category.id);
  }

  return formData;
};

export const createAdBanner = async (
  values: AdminCreateAdBannerValues,
  type: AdBannerType,
  query?: { linkToZoneId?: string }
) => {
  const formData = prepareBannerFormData(new FormData(), values);

  return httpRequest.runRequest<AdBanner>({
    ...adminAdsRequests.createAdBanner({
      requestParams: { type, ...query },
      // @ts-ignore
      requestPayload: formData
    })
  });
};

export const updateAdBanner = async (bannerId: string, type: AdBannerType, values: AdminUpdateAdBannerValues) => {
  const formData = prepareBannerFormData(new FormData(), values);

  return await httpRequest.runRequest<AdBanner>({
    ...adminAdsRequests.updateAdBanner({
      requestParams: { bannerId, type },
      // @ts-ignore
      requestPayload: formData
    })
  });
};

export const deleteAdBanner = async (bannerId: string, type: AdBannerType) => {
  return await httpRequest.runRequest<void>(
    adminAdsRequests.deleteAdBanner({
      requestParams: { bannerId, type }
    })
  );
};

export const getZones = async (advertiserId: string, sizes: string[]) => {
  return httpRequest.runRequest<{ data: { zone: AdZone; banner: AdBanner }[] }>(
    adminAdsRequests.getZones({
      requestParams: { sizes: sizes.join('|'), advertiserId }
    })
  );
};

export const getAdZones = async (region: string, districtCode: string) => {
  return httpRequest.runRequest<{ data: OrganizationAdZone[] }>(
    adminAdsRequests.getAdZones({
      requestParams: { region, districtCode }
    })
  );
};

export const getZonesByRegionAccountNumber = async (regionAccountNumber: string, size: string) => {
  return httpRequest.runRequest<{ data: { zone: AdZone; banners: AdBanner[] }[] }>(
    adminAdsRequests.getZonesByRegionAccountNumber({
      requestParams: { regionAccountNumber, size }
    })
  );
};

export const getRegionZonesAdsAssignments = async (regionAccountNumber: string, size?: string) => {
  return httpRequest.runRequest<{ data: RegionZonesAdsAssignments[] }>(
    adminAdsRequests.getRegionZonesAdsAssignments({
      requestParams: { regionAccountNumber, size }
    })
  );
};

export const indexOrganizationBanners = async (organizationId: string) => {
  return httpRequest.runRequest<{
    data: AdBanner[];
    organization: AdminOrganization;
    emailTemplates: AdminEmailTemplate[];
  }>(
    adminAdsRequests.indexOrganizationBanners({
      requestParams: { organizationId }
    })
  );
};

export const indexHouseAds = async () => {
  return httpRequest.runRequest<{
    data: AdBanner[];
    advertiser: Advertiser;
    emailTemplates: AdminEmailTemplate[];
    categories: AdCategory[];
  }>(adminAdsRequests.indexHouseAds());
};

export const getChartData = async (startDate: string, endDate: string) => {
  return httpRequest.runRequest<{
    data: ChartDataByDays;
  }>(
    adminAdsRequests.getChartData({
      requestParams: { startDate, endDate }
    })
  );
};

export const getAdvertiserStatistics = async (startDate: string, endDate: string) => {
  return httpRequest.runRequest<{
    data: AdvertiserStatistics;
  }>(
    adminAdsRequests.getAdvertiserStatistics({
      requestParams: { startDate, endDate }
    })
  );
};

export const getAdvertiserBannerStatistics = async (advertiserId: number, startDate: string, endDate: string) => {
  return httpRequest.runRequest<{
    data: AdvertiserBannerStatistics;
  }>(
    adminAdsRequests.getAdvertiserBannerStatistics({
      requestParams: { advertiserId, startDate, endDate }
    })
  );
};

export const indexHouseAdsCategories = async () => {
  return httpRequest.runRequest<{ data: AdCategory[] }>(adminAdsRequests.indexHouseAdsCategories());
};

export const createAdCategory = async (payload: AdminCreateAdCategoryValues) => {
  return httpRequest.runRequest<AdCategory>(
    adminAdsRequests.createAdCategory({
      requestPayload: payload
    })
  );
};

export const updateAdCategory = async (id: string, payload: AdminUpdateAdCategoryValues) => {
  return httpRequest.runRequest<AdCategory>(
    adminAdsRequests.updateAdCategory({
      requestPayload: payload,
      requestParams: { id }
    })
  );
};

export const removeAdCategory = async (category: AdCategory) => {
  return httpRequest.runRequest<AdCategory>(
    adminAdsRequests.removeAdCategory({
      requestParams: { id: category.id }
    })
  );
};

export const deleteMobileCampaign = async (bannerId: string, type: AdBannerType) => {
  return await httpRequest.runRequest<void>(
    adminAdsRequests.deleteMobileCampaign({
      requestParams: { bannerId, type }
    })
  );
};

export const indexAdvertiserBanners = async (advertiserId: string, type: AdBannerType) => {
  return httpRequest.runRequest<{
    data: AdCampaign[];
  }>(
    adminAdsRequests.indexAdvertiserBanners({
      requestParams: { advertiserId, type }
    })
  );
};

export const showBanner = async (bannerId: string, type: AdBannerType) => {
  return httpRequest.runRequest<{ zones: string[]; banner: AdBanner }>(
    adminAdsRequests.showBanner({
      requestParams: { bannerId, type }
    })
  );
};

export const linkBannerToZones = async (bannerId: string, payload: { zoneIds: string[]; allZones: boolean }) => {
  return httpRequest.runRequest<AdCategory>(
    adminAdsRequests.linkBannerToZones({
      requestPayload: payload,
      requestParams: { bannerId }
    })
  );
};
