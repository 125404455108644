import { ApplicationRoute } from '../../../../../typings';
import linksConstants from '../../../../../config/app/linksConstants';
import DoAppSponsorAdsPage from './DoAppSponsorAdsPage';

const DoAppSponsorsRoutes: ApplicationRoute[] = [
  {
    path: linksConstants.DO_APP.SPONSORS.ADS(':sponsorId'),
    exact: false,
    name: 'doAppSponsorAds',
    public: false,
    show: true,
    component: DoAppSponsorAdsPage,
    scope: []
  }
];

export default DoAppSponsorsRoutes;
