import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoResultsFound, PageContainer } from '../../PageStyledComponents';
import PageHeader from '../../../Shared/Layout/PageHeader';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import linksConstants from '../../../../config/app/linksConstants';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AdminOrganization } from '../../../../store/AdminOrganizations/types';
import { RouteComponentProps } from 'react-router';
import Loader from '../../../Shared/Loading/Loader';
import { AdBanner, AdBannerType, AdCampaign } from '../../../../store/AdminAds/types';
import { adminAdsOperations } from '../../../../store/AdminAds';
import AdminAdsTable from '../Ads/AdminAdsTable';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import AdminCreateAdBannerWindow from '../Ads/Windows/AdBanner/AdminCreateAdBannerWindow';
import AdminUpdateAdBannerWindow from '../Ads/Windows/AdBanner/AdminUpdateAdBannerWindow';
import AdminDeleteAdBannerWindow from '../Ads/Windows/AdBanner/AdminDeleteAdBannerWindow';
import { hasPermission } from '../../../../utils/permissions';
import { useTypedSelector } from '../../../../utils';
import AdminMobileAdCampaignsTable from '../Ads/AdminMobileAdCampaignsTable';
import AdminListZonesWindow from '../Ads/Windows/AdBanner/AdminListZonesWindow';
import { AppBar, Box, Button, Paper, Tab, Tabs } from '@material-ui/core';
import styled from 'styled-components';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MobileFriendlyOutlinedIcon from '@material-ui/icons/MobileFriendlyOutlined';
import AdminShowOrganizationAdsAssignmentsWindow from './Windows/AdminShowOrganizationAdsAssignmentsWindow';

type AdminOrganizationsDigitalAdsPageProps = RouteComponentProps<{ organizationId: string }> & {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: OrganizationDigitalAdsTabs;
  value: OrganizationDigitalAdsTabs;
}

enum OrganizationDigitalAdsTabs {
  EMAIL,
  MOBILE
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`organization-ads-tabpanel-${index}`}
      aria-labelledby={`organization-ads-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: OrganizationDigitalAdsTabs) {
  return {
    id: `organization-ads-tab-${index}`,
    'aria-controls': `organization-ads-tabpanel-${index}`
  };
}

const AdminOrganizationsDigitalAdsPage: FunctionComponent<AdminOrganizationsDigitalAdsPageProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);

  const [loading, setLoading] = useState<boolean>(true);
  const [organization, setOrganization] = useState<AdminOrganization | null>(null);
  const [banners, setBanners] = useState<AdBanner[]>([]);
  const [mobileCampaigns, setMobileCampaigns] = useState<AdCampaign[]>([]);
  const [error, setError] = useState<boolean>(false);

  const [activeBanner, setActiveBanner] = useState<AdBanner | null>(null);
  const [activeBannerType, setActiveBannerType] = useState<AdBannerType.EMAIL | AdBannerType.MOBILE_MERCHANT>(
    AdBannerType.EMAIL
  );

  const [updateAdBannerWindowOpen, onUpdateAdBannerWindowOpen, onUpdateAdBannerWindowClose] = useOpenHandler();
  const [deleteAdBannerWindowOpen, onDeleteAdBannerWindowOpen, onDeleteAdBannerWindowClose] = useOpenHandler();
  const [createAdBannerWindowOpen, onCreateAdBannerWindowOpen, onCreateAdBannerWindowClose] = useOpenHandler();
  const [listZonesWindowOpen, onListZonesWindowOpen, onListZonesWindowClose] = useOpenHandler();
  const [
    organizationAdsAssignmentsWindowOpen,
    onOrganizationAdsAssignmentsWindowOpen,
    onOrganizationAdsAssignmentsWindowClose
  ] = useOpenHandler();

  const [tabValue, setTabValue] = React.useState<OrganizationDigitalAdsTabs>(OrganizationDigitalAdsTabs.EMAIL);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: OrganizationDigitalAdsTabs) => {
    setTabValue(newValue);
  };

  const fetchData = async (initialLoad?: boolean) => {
    if (initialLoad) {
      setLoading(true);
    }
    try {
      setError(false);
      const bannersResponse = await adminAdsOperations.indexOrganizationBanners(match.params.organizationId);
      setBanners(bannersResponse.data);
      setOrganization(bannersResponse.organization);

      if (bannersResponse.organization.advertiserId) {
        const mobileCampaignsResponse = await adminAdsOperations.indexAdvertiserBanners(
          bannersResponse.organization.advertiserId,
          AdBannerType.MOBILE_MERCHANT
        );
        setMobileCampaigns(mobileCampaignsResponse.data);
      }

      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [match.params.organizationId]);

  const backButton = {
    onClick: () => dispatch(push(linksConstants.ADMINISTRATION.ORGANIZATIONS.INDEX)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      {error && <NoResultsFound>{t('pages.adminOrganizations.errorOnAdsFetch')}</NoResultsFound>}
      {loading && <Loader />}
      {!loading && organization && (
        <>
          <PageHeader
            title={`${organization.name} - ${t('pages.adminOrganizations.titleAds')}`}
            leftActionButtons={[backButton]}
          />
          {organization.advertiserId && (
            <Paper style={{ marginTop: '20px' }}>
              <AppBar
                position="static"
                color="default"
                elevation={0}
                style={{ background: 'none', borderBottom: '1px solid #eee' }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="organization-ads-tabs"
                  indicatorColor="secondary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <StyledTab
                    label={t('pages.adminAds.email')}
                    icon={<EmailOutlinedIcon />}
                    {...a11yProps(OrganizationDigitalAdsTabs.EMAIL)}
                  />
                  <StyledTab
                    label={t('pages.adminAds.mobile')}
                    icon={<MobileFriendlyOutlinedIcon />}
                    {...a11yProps(OrganizationDigitalAdsTabs.MOBILE)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={OrganizationDigitalAdsTabs.EMAIL}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" padding="15px">
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() =>
                      dispatch(
                        push(linksConstants.ADMINISTRATION.ORGANIZATIONS.ADS_ASSIGNMENT(match.params.organizationId))
                      )
                    }
                    style={{ marginRight: '10px' }}
                  >
                    <VisibilityIcon /> {t('pages.adminAds.openAssignments')}
                  </Button>
                  {hasPermission(role, ['adsAdd']) && (
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      onClick={() => {
                        setActiveBannerType(AdBannerType.EMAIL);
                        onCreateAdBannerWindowOpen();
                      }}
                    >
                      <AddIcon /> {t('pages.adminAds.addNewBanner')}
                    </Button>
                  )}
                </Box>
                <AdminAdsTable
                  banners={banners}
                  isLoading={loading}
                  onUpdateAdBanner={(banner) => {
                    setActiveBannerType(AdBannerType.EMAIL);
                    setActiveBanner(banner);
                    onUpdateAdBannerWindowOpen();
                  }}
                  onRemoveAdBanner={(banner) => {
                    setActiveBannerType(AdBannerType.EMAIL);
                    setActiveBanner(banner);
                    onDeleteAdBannerWindowOpen();
                  }}
                  elevation={0}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={OrganizationDigitalAdsTabs.MOBILE}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" padding="15px">
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={onOrganizationAdsAssignmentsWindowOpen}
                    style={{ marginRight: '10px' }}
                  >
                    <VisibilityIcon /> {t('pages.adminAds.openAssignments')}
                  </Button>
                  {hasPermission(role, ['adsAdd']) && (
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      onClick={() => {
                        setActiveBannerType(AdBannerType.MOBILE_MERCHANT);
                        onCreateAdBannerWindowOpen();
                      }}
                    >
                      <AddIcon /> {t('pages.adminAds.addMerchantBanner')}
                    </Button>
                  )}
                </Box>
                <AdminMobileAdCampaignsTable
                  campaigns={mobileCampaigns}
                  isLoading={loading}
                  onUpdateAdBanner={(banner) => {
                    setActiveBannerType(AdBannerType.MOBILE_MERCHANT);
                    setActiveBanner(banner);
                    onUpdateAdBannerWindowOpen();
                  }}
                  onRemoveAdBanner={(banner) => {
                    setActiveBannerType(AdBannerType.MOBILE_MERCHANT);
                    setActiveBanner(banner);
                    onDeleteAdBannerWindowOpen();
                  }}
                  onListZones={(banner) => {
                    setActiveBannerType(AdBannerType.MOBILE_MERCHANT);
                    setActiveBanner(banner);
                    onListZonesWindowOpen();
                  }}
                  elevation={0}
                />
              </TabPanel>
            </Paper>
          )}

          {organization.advertiserId && (
            <AdminCreateAdBannerWindow
              advertiserId={organization.advertiserId}
              organization={organization}
              open={createAdBannerWindowOpen}
              categories={[]}
              onCloseClick={onCreateAdBannerWindowClose}
              fullScreenOnMobile={true}
              showCategories={false}
              onFormSuSubmit={fetchData}
              type={activeBannerType}
            />
          )}
          {organization.advertiserId && (
            <AdminUpdateAdBannerWindow
              advertiserId={organization.advertiserId}
              organization={organization}
              banner={activeBanner}
              categories={[]}
              open={updateAdBannerWindowOpen}
              onCloseClick={onUpdateAdBannerWindowClose}
              fullScreenOnMobile={true}
              showCategories={false}
              onFormSuSubmit={fetchData}
              type={activeBannerType}
            />
          )}
          <AdminDeleteAdBannerWindow
            banner={activeBanner}
            open={deleteAdBannerWindowOpen}
            onCloseClick={onDeleteAdBannerWindowClose}
            fullScreenOnMobile={true}
            onFormSuSubmit={fetchData}
            type={activeBannerType}
          />
          <AdminListZonesWindow
            open={listZonesWindowOpen}
            banner={activeBanner}
            organization={organization}
            onCloseClick={onListZonesWindowClose}
            fullScreenOnMobile={true}
            onSaveSuccess={fetchData}
          />
          <AdminShowOrganizationAdsAssignmentsWindow
            organization={organization}
            open={organizationAdsAssignmentsWindowOpen}
            onCloseClick={onOrganizationAdsAssignmentsWindowClose}
            fullScreenOnMobile
          />
        </>
      )}
    </PageContainer>
  );
};

export default AdminOrganizationsDigitalAdsPage;

const StyledTab = styled(Tab)`
  min-height: 50px;
  text-transform: none;
  font-size: 1rem;
  & .MuiTab-wrapper {
    flex-direction: row;
    .MuiSvgIcon-root {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
`;
