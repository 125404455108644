import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toast from '../../../../../Shared/Toast/Toast';
import app from '../../../../../../config/app/app';
import { FormikProps } from 'formik';
import {
  AdBanner,
  AdBannerType,
  AdminUpdateAdBannerValues,
  HouseAdsBannersCategory
} from '../../../../../../store/AdminAds/types';
import { adminAdsOperations } from '../../../../../../store/AdminAds';
import AdBannerForm from '../../Partials/AdBannerForm';
import moment from 'moment';
import { AdminOrganization } from '../../../../../../store/AdminOrganizations/types';
import isUri from '@stdlib/assert-is-uri';

type AdminUpdateAdBannerWindowProps = {
  open: boolean;
  advertiserId: string;
  banner: AdBanner | null;
  categories: HouseAdsBannersCategory[];
  organization?: AdminOrganization;
  onCloseClick: () => void;
  onFormSuSubmit?: () => void;
  fullScreenOnMobile: boolean;
  showDates?: boolean;
  showCategories?: boolean;
  showOrganization?: boolean;
  bannerSize?: number[];
  type: AdBannerType;
};

const AdminUpdateAdBannerWindow: FunctionComponent<AdminUpdateAdBannerWindowProps> = ({
  advertiserId,
  categories,
  banner,
  organization,
  onCloseClick,
  onFormSuSubmit,
  open,
  showDates = true,
  showCategories = true,
  showOrganization = true,
  bannerSize,
  type
}) => {
  const { t } = useTranslation();
  const bannerFileRef = useRef<HTMLInputElement | null>(null);
  const bannerFilePreviewRef = useRef<HTMLImageElement | null>(null);
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [bannerFilePreviewUrl, setBannerFilePreviewUrl] = useState<string | null>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (open && banner) {
      setWidth(parseInt(banner.width));
      setHeight(parseInt(banner.height));
      setBannerFile(null);
      setBannerFilePreviewUrl(banner.imageURL);
    }
  }, [open]);

  useEffect(() => {
    if (bannerFilePreviewRef.current) {
      bannerFilePreviewRef.current.onload = () => {
        if (bannerFilePreviewRef.current) {
          setWidth(bannerFilePreviewRef.current?.width);
          setHeight(bannerFilePreviewRef.current?.height);
        }

        if (bannerFilePreviewRef.current) {
          bannerFilePreviewRef.current.onload = null;
        }
      };
    }
  }, [bannerFilePreviewUrl]);

  if (!banner) {
    return null;
  }

  const onSubmit = async (values: AdminUpdateAdBannerValues) => {
    try {
      if (banner && advertiserId) {
        await adminAdsOperations.updateAdBanner(banner.bannerId, type, {
          ...values,
          weight: '1',
          storageType: 'url',
          target: '_blank',
          width: width.toString(),
          height: height.toString(),
          bannerFile,
          advertiserId
        });

        onCloseClick();
        if (onFormSuSubmit) {
          onFormSuSubmit();
        }
        Toast.success(t('notifications.adminAds.successOperation'));
      }
    } catch (e) {
      Toast.error(t('notifications.adminAds.errorOperation'));
    }
  };

  const onBannerFileChoose = async (e: ChangeEvent<HTMLInputElement>, props: FormikProps<any>) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const extension = file.name.split('.').pop();

      if (!app.validLogoExtensions.includes((extension || '').toLowerCase())) {
        Toast.error(t('notifications.uploadLogo.invalidExtension'));
        return;
      }

      if (file.size > app.maxLogoSize) {
        Toast.error(t('notifications.uploadLogo.fileTooBig'));
        return;
      }

      // Create a temporary image to validate dimensions
      const img = new Image();
      img.onload = () => {
        if (bannerSize) {
          const [adWidth, adHeight] = bannerSize;

          if (img.width !== adWidth || img.height !== adHeight) {
            Toast.error(t('notifications.adminAds.invalidDimensions', { adWidth, adHeight }));
            return;
          }
        }

        setBannerFilePreviewUrl(URL.createObjectURL(file));
        setBannerFile(file);

        props.setFieldValue('dirtyIndicator', `${props.values.dirtyIndicator}_`);
      };

      img.onerror = () => {
        Toast.error(t('notifications.adminAds.errorImageLoad'));
      };

      img.src = URL.createObjectURL(file);
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <AdBannerForm
        bannerFilePreviewUrl={bannerFilePreviewUrl}
        onBannerFileChoose={(e) => onBannerFileChoose(e, props)}
        width={width}
        height={height}
        categories={categories}
        bannerFileRef={bannerFileRef}
        bannerFilePreviewRef={bannerFilePreviewRef}
        startDate={props.values.startDate}
        endDate={props.values.endDate}
        showDates={showDates}
        showCategories={showCategories}
        showOrganization={showOrganization}
      />
    </>
  );

  const startDate = moment(banner.startDate);
  const endDate = moment(banner.endDate);

  const currentCategory = categories.find((data) => data.category.id === banner.categoryId);

  return (
    <ModalFormWindow
      open={open}
      okButtonText={t('common.update')}
      header={t('pages.adminAds.windows.updateAdBanner')}
      onCloseClick={() => {
        setBannerFilePreviewUrl(null);
        onCloseClick();
      }}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        bannerName: banner.bannerName,
        alt: banner.alt,
        url: banner.url,
        comments: banner.comments,
        startDate: startDate.isValid() ? startDate.format(app.dateFormats.inputValueDate) : '',
        endDate: endDate.isValid() ? endDate.format(app.dateFormats.inputValueDate) : '',
        organization: organization ? `(${organization.accountNumber}) ${organization.name}` : 'House Ads',
        category: showCategories
          ? { id: currentCategory?.category?.id || null, label: currentCategory?.category?.name || null }
          : null,
        dirtyIndicator: ''
      }}
      validationSchema={Yup.object().shape({
        bannerName: Yup.string().max(app.maxInputLength).required(),
        alt: Yup.string().max(app.maxInputLength).required(),
        url: Yup.string()
          .max(app.maxInputLength)
          .required()
          .test('validate-uri', t('formValidationErrors.validUri'), (uri: string) => isUri(uri)),
        startDate: Yup.string().required(),
        endDate: Yup.string().nullable(),
        comments: Yup.string(),
        ...(showCategories
          ? {
              category: Yup.object()
                .shape({
                  id: Yup.string(),
                  label: Yup.string()
                })
                .optional(),
              comments: Yup.string()
            }
          : {}),
        dirtyIndicator: Yup.string()
      })}
      maxWidth="md"
    />
  );
};

export default AdminUpdateAdBannerWindow;
