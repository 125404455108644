import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOrganization } from '../../../../../../store/AdminOrganizations/types';
import ModalConfirmationWindow from '../../../../../Shared/Window/ModalConfirmationWindow';
import Toast from '../../../../../Shared/Toast/Toast';
import Loader from '../../../../../Shared/Loading/Loader';
import { adminAdsOperations } from '../../../../../../store/AdminAds';
import { AdBanner, AdBannerType, AdZone } from '../../../../../../store/AdminAds/types';
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
  Divider,
  FormControlLabel,
  Box
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type AdminListZonesWindowProps = {
  open: boolean;
  banner: AdBanner | null;
  organization: AdminOrganization | null;
  onCloseClick: () => void;
  onSaveSuccess: () => void;
  fullScreenOnMobile: boolean;
};

const AdminListZonesWindow: FunctionComponent<AdminListZonesWindowProps> = ({
  banner,
  organization,
  onCloseClick,
  open,
  onSaveSuccess
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [zones, setZones] = React.useState<AdZone[]>([]);
  const [selectedZoneIds, setSelectedZoneIds] = React.useState<string[]>([]);
  const [allZones, setAllZones] = React.useState<boolean>(false);

  const fetchZones = async () => {
    setZones([]);
    setLoading(true);
    try {
      if (open && organization?.region && banner) {
        const response = await adminAdsOperations.getZonesByRegionAccountNumber(
          organization.region,
          `${banner.width}x${banner.height}`
        );
        setZones(response.data.map((item) => item.zone));

        const bannerResponse = await adminAdsOperations.showBanner(banner.bannerId, AdBannerType.MOBILE_MERCHANT);
        setSelectedZoneIds(bannerResponse.zones.map((zone) => zone));
      }
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchZones();
  }, [open]);

  useEffect(() => {
    const allSelected = zones.length > 0 && selectedZoneIds.length === zones.length;
    setAllZones(allSelected);
  }, [selectedZoneIds, zones]);

  const handleToggle = (zoneId: string) => {
    setSelectedZoneIds((prev) => (prev.includes(zoneId) ? prev.filter((id) => id !== zoneId) : [...prev, zoneId]));
  };

  const handleToggleAllZones = () => {
    if (allZones) {
      setSelectedZoneIds([]);
    } else {
      setSelectedZoneIds(zones.map((zone) => zone.zoneId));
    }
  };

  const handleSave = async () => {
    if (!banner) return;
    setSaving(true);
    try {
      await adminAdsOperations.linkBannerToZones(banner.bannerId, {
        zoneIds: selectedZoneIds,
        allZones
      });
      onSaveSuccess();
      onCloseClick();
      Toast.success(t('notifications.common.successOperation'));
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    } finally {
      setSaving(false);
    }
  };

  if (!organization) return null;

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.adminAds.windows.zones')}
      okButtonText={t('common.close')}
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      {loading && <Loader />}
      {!loading && (
        <>
          {zones.length > 0 && (
            <Box width="100%" bgcolor="#f2f2f2" pl="15px">
              <FormControlLabel
                control={<Checkbox checked={allZones} onChange={handleToggleAllZones} />}
                label={t('common.selectAll')}
              />
            </Box>
          )}
          <List dense style={{ paddingTop: 0 }}>
            {zones.map((zone) => (
              <React.Fragment key={zone.zoneId}>
                <ListItem button onClick={() => handleToggle(zone.zoneId)}>
                  <ListItemIcon style={{ minWidth: 'initial' }}>
                    <Checkbox
                      edge="start"
                      checked={selectedZoneIds.includes(zone.zoneId)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={zone.comments} secondary={zone.zoneName} />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          {zones.length > 0 && (
            <Button variant="contained" color="primary" onClick={handleSave} disabled={saving}>
              {t('common.save')}
            </Button>
          )}
          {zones.length === 0 && (
            <Alert severity="info" style={{ marginBottom: 16 }}>
              {t('pages.adminAds.windows.noZones')}
            </Alert>
          )}
        </>
      )}
    </ModalConfirmationWindow>
  );
};

export default AdminListZonesWindow;
