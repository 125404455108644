import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import { DoAppMerchant } from '../../../../../store/DoApp/Merchants/types';

type DoAppAddIncentiveCreditsProps = {
  open: boolean;
  merchant: DoAppMerchant;
  onCloseClick: () => void;
  onSubmit: (newCredits: number, note?: string) => void;
};

const DoAppAddIncentiveCredits: FunctionComponent<DoAppAddIncentiveCreditsProps> = ({
  open,
  merchant,
  onCloseClick,
  onSubmit
}) => {
  const { t } = useTranslation();
  const [credits, setCredits] = useState<string>('0');
  const [note, setNote] = useState<string>();

  useEffect(() => {
    if (open) {
      setCredits('0');
      setNote('');
    }
  }, [open]);

  const handleSubmit = () => {
    const parsedCredits = parseFloat(credits);

    if (isNaN(parsedCredits) || parsedCredits < 0) {
      Toast.error(t('pages.doApp.merchants.invalidCredits'));
      return;
    }
    onSubmit(Math.round(parsedCredits * 100), note);
  };

  const handleCreditsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow only numbers and one dot
    const validValue = inputValue.match(/^\d*\.?\d*$/); // Regex allows numbers and a single dot
    if (validValue) {
      setCredits(inputValue);
    }
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.merchants.addCredits')}
      okButtonText={t('common.submit')}
      disableSubmitButton={credits === '0'}
      onCloseClick={onCloseClick}
      onSubmit={handleSubmit}
    >
      <Row>
        <Strong>{merchant.name}</Strong>
        <br />
        {t('pages.doApp.merchants.currentAmount')}: <Strong>${(merchant.incentiveCredits / 100).toFixed(2)}</Strong>
      </Row>
      <Row>
        <TextField
          label={t('pages.doApp.merchants.credits')}
          type="text"
          value={credits}
          onChange={handleCreditsChange}
          fullWidth
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </Row>
      <Row>
        <TextField
          label={t('pages.doApp.merchants.comments')}
          type="text"
          value={note}
          onChange={handleNoteChange}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Row>
    </ModalConfirmationWindow>
  );
};

export default DoAppAddIncentiveCredits;
