import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { RouteComponentProps } from 'react-router';
import { useTypedSelector } from '../../../../../utils';
import linksConstants from '../../../../../config/app/linksConstants';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import { adminAdsOperations } from '../../../../../store/AdminAds';
import { AdBanner, AdBannerType, AdCampaign } from '../../../../../store/AdminAds/types';
import { hasPermission } from '../../../../../utils/permissions';
import PageHeader, { HeaderActionButton } from '../../../../Shared/Layout/PageHeader';
import Loader from '../../../../Shared/Loading/Loader';
import { Row } from '../../../../Shared/StyledComponents';
import { NoResultsFound, PageContainer, PageContent } from '../../../PageStyledComponents';
import AdminMobileAdCampaignsTable from '../../Ads/AdminMobileAdCampaignsTable';
import AdminCreateAdBannerWindow from '../../Ads/Windows/AdBanner/AdminCreateAdBannerWindow';
import AdminDeleteAdBannerWindow from '../../Ads/Windows/AdBanner/AdminDeleteAdBannerWindow';
import AdminUpdateAdBannerWindow from '../../Ads/Windows/AdBanner/AdminUpdateAdBannerWindow';
import { DoAppSponsor } from '../../../../../store/DoApp/Sponsors/types';
import { doAppSponsorsOperations } from '../../../../../store/DoApp/Sponsors';

type DoAppSponsorAdsProps = RouteComponentProps<{ sponsorId: string }> & {};

const DoAppSponsorAds: FunctionComponent<DoAppSponsorAdsProps> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useTypedSelector((state) => state.auth.role);

  const [loading, setLoading] = useState<boolean>(true);
  const [sponsor, setSponsor] = useState<DoAppSponsor | null>(null);
  const [mobileCampaigns, setMobileCampaigns] = useState<AdCampaign[]>([]);
  const [error, setError] = useState<boolean>(false);

  const [activeBanner, setActiveBanner] = useState<AdBanner | null>(null);

  const [updateAdBannerWindowOpen, onUpdateAdBannerWindowOpen, onUpdateAdBannerWindowClose] = useOpenHandler();
  const [deleteAdBannerWindowOpen, onDeleteAdBannerWindowOpen, onDeleteAdBannerWindowClose] = useOpenHandler();
  const [
    createMobileAdBannerWindowOpen,
    onCreateMobileAdBannerWindowOpen,
    onCreateMobileAdBannerWindowClose
  ] = useOpenHandler();

  const fetchData = async (initialLoad?: boolean) => {
    if (initialLoad) {
      setLoading(true);
    }
    try {
      setError(false);
      const sponsorResponse = await doAppSponsorsOperations.getSponsor(match.params.sponsorId);
      setSponsor(sponsorResponse.data);

      const mobileCampaignsResponse = await adminAdsOperations.indexAdvertiserBanners(
        sponsorResponse.data.advertiserId,
        AdBannerType.MOBILE_SPONSOR
      );

      setMobileCampaigns(mobileCampaignsResponse.data);

      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [match.params.sponsorId]);

  const getActionButtons = () => {
    const buttons: HeaderActionButton[] = [];

    if (hasPermission(role, ['adsAdd'])) {
      buttons.push({
        label: t('pages.adminAds.addMerchantBanner'),
        icon: <AddIcon />,
        onClick: () => onCreateMobileAdBannerWindowOpen()
      });
    }

    return buttons;
  };

  const backButton = {
    onClick: () => dispatch(push(linksConstants.DO_APP.DASHBOARD)),
    label: t('common.back'),
    icon: <KeyboardArrowLeftIcon />
  };

  return (
    <PageContainer>
      {error && <NoResultsFound>{t('pages.adminOrganizations.errorOnAdsFetch')}</NoResultsFound>}
      {loading && <Loader />}
      {!loading && sponsor && (
        <>
          <PageHeader
            title={`${sponsor.name} - ${t('pages.adminOrganizations.titleAds')}`}
            leftActionButtons={[backButton]}
            rightActionButtons={getActionButtons()}
          />
          <PageContent>
            {sponsor.advertiserId && (
              <Row>
                <AdminMobileAdCampaignsTable
                  campaigns={mobileCampaigns}
                  isLoading={loading}
                  onUpdateAdBanner={(banner) => {
                    setActiveBanner(banner);
                    onUpdateAdBannerWindowOpen();
                  }}
                  onRemoveAdBanner={(banner) => {
                    setActiveBanner(banner);
                    onDeleteAdBannerWindowOpen();
                  }}
                />
              </Row>
            )}
          </PageContent>

          {sponsor.advertiserId && (
            <AdminCreateAdBannerWindow
              advertiserId={sponsor.advertiserId}
              adZoneId={sponsor.adZoneId}
              open={createMobileAdBannerWindowOpen}
              categories={[]}
              onCloseClick={onCreateMobileAdBannerWindowClose}
              fullScreenOnMobile={true}
              showCategories={false}
              onFormSuSubmit={fetchData}
              type={AdBannerType.MOBILE_SPONSOR}
              bannerSize={[300, 600]}
              showOrganization={false}
            />
          )}
          {sponsor.advertiserId && (
            <AdminUpdateAdBannerWindow
              advertiserId={sponsor.advertiserId}
              banner={activeBanner}
              categories={[]}
              open={updateAdBannerWindowOpen}
              onCloseClick={onUpdateAdBannerWindowClose}
              fullScreenOnMobile={true}
              showCategories={false}
              onFormSuSubmit={fetchData}
              bannerSize={[300, 600]}
              showOrganization={false}
              type={AdBannerType.MOBILE_SPONSOR}
            />
          )}
          <AdminDeleteAdBannerWindow
            banner={activeBanner}
            open={deleteAdBannerWindowOpen}
            onCloseClick={onDeleteAdBannerWindowClose}
            fullScreenOnMobile={true}
            onFormSuSubmit={fetchData}
            type={AdBannerType.MOBILE_SPONSOR}
          />
        </>
      )}
    </PageContainer>
  );
};

export default DoAppSponsorAds;
