import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { CreateSponsorRequestBodyDto, IndexDoAppSponsorsRequestParams } from './types';

export const index = (settings: RequestSettings<IndexDoAppSponsorsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/sponsors?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, CreateSponsorRequestBodyDto>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/sponsors'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});

export const getRegionApps = (): BaseSettings => ({
  query: {
    method: 'get',
    url: '/api/v1/admin/organizations/do_app/apps'
  }
});

export const getSponsor = (settings: RequestSettings<{ sponsorId: string }>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/sponsors/:sponsorId`
  },
  params: settings.requestParams
});
