import React, { FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageContent, PageContentPaper } from '../../../PageStyledComponents';
import PageHeader from '../../../../Shared/Layout/PageHeader';
import DoAppMerchantsList from '../Widgets/DoAppMerchantsList';
import DoAppPromotionsList from '../Widgets/DoAppPromotionsList';
import DoAppPromotionClaimsList from '../Widgets/DoAppPromotionClaimsList';
import DoAppCreditTransactionsList from '../Widgets/DoAppCreditTransactionsList';
import DoAppSubscribersList from '../Widgets/DoAppSubscribersList';
import styled from 'styled-components';
import DoAppSponsorsList from '../Widgets/DoAppSponsorsList';
import DoAppRunningPromotionsList from '../Widgets/DoAppRunningPromotionsList';
import DoAppRunningPromotionReportsList from '../Widgets/DoAppRunningPromotionReportsList';

type DoAppDashboardPageProps = {};

const DoAppDashboardPage: FunctionComponent<DoAppDashboardPageProps> = () => {
  const { t } = useTranslation();

  const subscribersListRef = useRef<{ refreshSubscribers: () => void }>(null);

  const handleTransactionCreated = () => {
    if (subscribersListRef.current) {
      subscribersListRef.current.refreshSubscribers();
    }
  };

  return (
    <PageContainer>
      <PageHeader title={t(`pages.doApp.dashboard.title`)} />
      <PageContent>
        <PageContentPaper>
          <DoAppMerchantsList />
        </PageContentPaper>
        <MasonryGrid>
          <MasonryItem>
            <PageContentPaper style={{ marginTop: 0 }}>
              <DoAppSubscribersList ref={subscribersListRef} />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppPromotionsList />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppPromotionClaimsList />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppCreditTransactionsList onTransactionCreated={handleTransactionCreated} />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppSponsorsList />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppRunningPromotionsList />
            </PageContentPaper>
          </MasonryItem>
          <MasonryItem>
            <PageContentPaper>
              <DoAppRunningPromotionReportsList />
            </PageContentPaper>
          </MasonryItem>
        </MasonryGrid>
      </PageContent>
    </PageContainer>
  );
};

export default DoAppDashboardPage;

const MasonryGrid = styled.div`
  column-count: 2; /* Number of masonry columns */
  column-gap: 16px; /* Space between the columns */
`;

const MasonryItem = styled.div`
  break-inside: avoid;
  margin-bottom: 16px; /* Space between the items vertically */
`;
