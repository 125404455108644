import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CachedIcon from '@material-ui/icons/Cached';
import { createNetworkErrorObject, useTypedSelector } from '../../../../utils';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@material-ui/core';
import Loader from '../../../Shared/Loading/Loader';
import { Alert } from '@material-ui/lab';
import { doAppMerchantsOperations } from '../../../../store/DoApp/Merchants';
import { GetMerchantResponse, MerchantRewardConfigurationType } from '../../../../store/DoApp/Merchants/types';
import RedeemIcon from '@material-ui/icons/Redeem';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import styled from 'styled-components';
import PromotionsTab from './MerchantDashboard/PromotionsTab';
import PromotionClaimsTab from './MerchantDashboard/PromotionClaimsTab';
import { DoAppPromotionClaimStatus } from '../../../../store/DoApp/PromotionClaims/types';
import CreditTransactionsTab from './MerchantDashboard/CreditTransactionsTab';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useOpenHandler from '../../../../hooks/useOpenHandler';
import PromotionalCreditsConfigurationWindow from '../Windows/PromotionalCreditsConfigurationWindow';
import IncentiveCreditsInfoWindow from '../Windows/IncentiveCreditsInfoWindow';
import SubscribersTab from './MerchantDashboard/SubscribersTab';
import { DoAppCreditTransactionStatus } from '../../../../store/DoApp/CreditTransactions/types';
import RedeemPromotionalCreditsWindow from '../Windows/RedeemPromotionalCreditsWindow';
import HistoryIcon from '@material-ui/icons/History';
import MerchantRewardsWindow from '../Windows/MerchantRewardsWindow';

type OrganizationMerchantProps = {
  onError?: () => void;
};

enum DoAppTabs {
  PROMOTIONS,
  PROMOTION_CLAIMS,
  CREDIT_TRANSACTIONS,
  SUBSCRIBERS
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: DoAppTabs;
  value: DoAppTabs;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`do-app-promotions-tabpanel-${index}`}
      aria-labelledby={`do-app-promotions-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: DoAppTabs) {
  return {
    id: `do-app-promotions-tab-${index}`,
    'aria-controls': `do-app-promotions-tabpanel-${index}`
  };
}

const OrganizationMerchant: FunctionComponent<OrganizationMerchantProps> = ({ onError }) => {
  const { t } = useTranslation();
  const tabsRef = useRef<HTMLDivElement>(null);
  const organization = useTypedSelector((state) => state.auth.organization);
  const [loading, setLoading] = useState<boolean>(true);
  const [merchantData, setMerchantData] = useState<GetMerchantResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [rewardType, setRewardType] = useState<'incentive' | 'promotional'>('incentive');

  const [tabValue, setTabValue] = useState<DoAppTabs>(DoAppTabs.PROMOTIONS);
  const [creditTransactionsDefaultFilter, setCreditTransactionsDefaultFilter] = useState<
    DoAppCreditTransactionStatus | undefined
  >();
  const [promotionClaimStatus, setPromotionClaimStatus] = useState<DoAppPromotionClaimStatus>(
    DoAppPromotionClaimStatus.PENDING
  );
  const [promotionId, setPromotionId] = useState<string | undefined>(undefined);
  const [
    promotionalCreditsConfigurationWindowOpen,
    onPromotionalCreditsConfigurationWindowOpen,
    onPromotionalCreditsConfigurationWindowClose
  ] = useOpenHandler();
  const [
    redeemPromotionalCreditsWindowOpen,
    onRedeemPromotionalCreditsWindowOpen,
    onRedeemPromotionalCreditsWindowClose
  ] = useOpenHandler();
  const [
    incentiveCreditsInfoWindowOpen,
    onIncentiveCreditsInfoWindowOpen,
    onIncentiveCreditsInfoWindowClose
  ] = useOpenHandler();
  const [merchantRewardsWindowOpen, onMerchantRewardsWindowOpen, onMerchantRewardsWindowClose] = useOpenHandler();

  const handleDoAppTabsChange = (event: React.ChangeEvent<{}>, newValue: DoAppTabs) => {
    setTabValue(newValue);
    setPromotionId(undefined);
  };

  const fetchData = async () => {
    if (organization) {
      try {
        const response = await doAppMerchantsOperations.getOrganizationMerchant();
        setMerchantData(response);
        setError(null);
      } catch (e) {
        const error = createNetworkErrorObject(e);

        if (error.statusCode === 404) {
          setError(t('pages.myOrganization.merchant.merchantNotFound'));
        } else {
          setError(t('pages.myOrganization.merchant.unknownError'));
        }

        onError && onError();
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const hasPaymentMethodConfigured = merchantData?.data.paymentMethodActive || false;

  const promotionalCreditsInDollars = (merchantData?.data.promotionalCredits || 0) / 100;
  const creditTransactionsAmountInDollars = (merchantData?.meta?.creditTransactionsAmount || 0) / 100;
  const incentiveCreditsInDollars = (merchantData?.data?.incentiveCredits || 0) / 100;
  const isRedeemEnabled = promotionalCreditsInDollars >= 1;

  const getTooltipTitleForRedeemButton = () => {
    if (!hasPaymentMethodConfigured) {
      return t('pages.myOrganization.merchant.redeemDisabledNoPaymentMethod').toString();
    }
    if (!isRedeemEnabled) {
      return t('pages.myOrganization.merchant.redeemDisabledNotEnoughCredits').toString();
    }

    return '';
  };

  return (
    <>
      {loading && <Loader />}

      {!loading && error && (
        <Alert severity="error" style={{ marginBottom: '1.5rem' }}>
          {error}
        </Alert>
      )}

      {!loading && !error && merchantData && (
        <>
          <Grid container>
            {!hasPaymentMethodConfigured && (
              <Grid item sm={12}>
                <Alert severity="warning" style={{ marginBottom: '1.5rem' }}>
                  {t('pages.myOrganization.merchant.paymentMethodNotConfigured')}
                  <IconButton aria-label="refresh" size="small" onClick={fetchData} style={{ marginLeft: 8 }}>
                    <CachedIcon fontSize="inherit" />
                  </IconButton>
                </Alert>
              </Grid>
            )}
            <Grid item md={5} sm={12}>
              <Typography variant="body1">
                {t('pages.myOrganization.merchant.name')}: <strong>{merchantData.data.name}</strong>
              </Typography>
              <Typography variant="body1">
                {t('pages.myOrganization.merchant.billingEmail')}: <strong>{merchantData.data.billingEmail}</strong>
              </Typography>
            </Grid>
            <Grid item md={5} sm={12}>
              <Typography variant="body1">
                {t('pages.myOrganization.merchant.region')}: <strong>{merchantData.data.region}</strong>
              </Typography>
              <Typography variant="body1">
                {t('pages.myOrganization.merchant.district')}: <strong>{merchantData.data.district}</strong>
              </Typography>
            </Grid>
            <Grid item md={2} sm={12}>
              <Button
                variant="outlined"
                onClick={async () => {
                  if (!window.Chargebee.inited) {
                    window.Chargebee.init({
                      site: window.env.__APP_CHARGEBEE_SITE__,
                      publishableKey: window.env.__APP_CHARGEBEE_PUBLISHABLE_KEY__
                    });
                  }

                  window.Chargebee.getInstance().setPortalSession(() =>
                    doAppMerchantsOperations.createCustomerPortalPaymentSession()
                  );

                  const portal = window.Chargebee.getInstance().createChargebeePortal();

                  portal.openSection(
                    { sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES },
                    {
                      close: () => setTimeout(fetchData, 3000),
                      paymentSourceAdd: () => setTimeout(fetchData, 4000),
                      paymentSourceRemove: () => setTimeout(fetchData, 4000),
                      paymentSourceUpdate: () => setTimeout(fetchData, 4000)
                    }
                  );
                }}
                startIcon={<LocalAtmOutlinedIcon />}
                style={{ marginTop: 5 }}
              >
                {t('pages.myOrganization.billingPortal')}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item lg={3} md={4} sm={12}>
              <Card
                style={{
                  borderRadius: 0,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderLeft: '10px solid #873ae2',
                  position: 'relative'
                }}
              >
                <CardContent
                  style={{
                    paddingBottom: 16,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography variant="body2">{t('pages.myOrganization.merchant.incentiveCredits')}</Typography>
                  <Typography variant="h5">
                    <strong>${incentiveCreditsInDollars.toFixed(2)}</strong>
                    <IconButton
                      size="small"
                      disableRipple
                      onClick={() => {
                        setRewardType('incentive');
                        onMerchantRewardsWindowOpen();
                      }}
                    >
                      <HistoryIcon fontSize="inherit" />
                    </IconButton>
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: 5, right: 5 }}>
                  <IconButton
                    aria-label="settings"
                    size="small"
                    disableRipple
                    onClick={onIncentiveCreditsInfoWindowOpen}
                  >
                    <InfoOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </Card>
            </Grid>
            <Grid item lg={3} md={4} sm={12}>
              <Card
                style={{
                  borderRadius: 0,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderLeft: '10px solid #3A7DE2',
                  position: 'relative'
                }}
              >
                <CardContent
                  style={{
                    paddingBottom: 16,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                    <div>
                      <Typography variant="body2">{t('pages.myOrganization.merchant.promotionalCredits')}</Typography>
                      <Typography variant="h5">
                        <strong>${promotionalCreditsInDollars.toFixed(2)}</strong>
                        <IconButton
                          size="small"
                          disableRipple
                          onClick={() => {
                            setRewardType('promotional');
                            onMerchantRewardsWindowOpen();
                          }}
                        >
                          <HistoryIcon fontSize="inherit" />
                        </IconButton>
                      </Typography>
                    </div>
                    <Tooltip title={getTooltipTitleForRedeemButton()}>
                      <span>
                        <StyledRedeemButton
                          variant="contained"
                          color="secondary"
                          size="small"
                          disabled={!isRedeemEnabled || !hasPaymentMethodConfigured}
                          startIcon={<RedeemIcon />}
                          onClick={onRedeemPromotionalCreditsWindowOpen}
                        >
                          {t('pages.myOrganization.merchant.redeem')}
                        </StyledRedeemButton>
                      </span>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title={t('pages.myOrganization.merchant.managePromotionalCredits').toString()}>
                      <>
                        <Typography variant="caption">
                          {t('pages.myOrganization.merchant.invoiceAutoApply', {
                            autoApplyOn:
                              merchantData &&
                              merchantData.data.rewardsConfigurationType ===
                                MerchantRewardConfigurationType.INVOICE_AUTO_APPLY
                                ? 'ON'
                                : 'OFF'
                          })}
                        </Typography>
                        <IconButton
                          aria-label="settings"
                          size="small"
                          disableRipple
                          onClick={onPromotionalCreditsConfigurationWindowOpen}
                        >
                          <SettingsIcon fontSize="inherit" />
                        </IconButton>
                      </>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={3} md={4} sm={12}>
              <Card
                style={{
                  borderRadius: 0,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderLeft: '10px solid #24B78A'
                }}
              >
                <CardContent
                  style={{
                    paddingBottom: 16,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                    <div>
                      <Typography variant="body2">
                        {t('pages.myOrganization.merchant.subscriberCreditsUsed')}
                      </Typography>
                      <Typography variant="h5">
                        <strong>${creditTransactionsAmountInDollars.toFixed(2)}</strong>
                      </Typography>
                    </div>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      startIcon={<ListAltIcon />}
                      style={{ flex: 'none', marginLeft: 10 }}
                      onClick={() => {
                        setTabValue(DoAppTabs.CREDIT_TRANSACTIONS);
                        setCreditTransactionsDefaultFilter(DoAppCreditTransactionStatus.ACCEPTED);
                        tabsRef.current?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      {t('pages.myOrganization.merchant.details')}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={3} md={4} sm={12}>
              <Card
                style={{
                  borderRadius: 0,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  borderLeft: '10px solid #EDAE3A'
                }}
              >
                <CardContent
                  style={{
                    paddingBottom: 16,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                    <div>
                      <Typography variant="body2">{t('pages.myOrganization.merchant.subscribersEngaged')}</Typography>
                      <Typography variant="h5">
                        <strong>{merchantData.meta?.subscribersEngaged || 0}</strong>
                      </Typography>
                    </div>
                    {/*<Button*/}
                    {/*  variant="outlined"*/}
                    {/*  color="secondary"*/}
                    {/*  size="small"*/}
                    {/*  startIcon={<ListAltIcon />}*/}
                    {/*  style={{ flex: 'none', marginLeft: 10 }}*/}
                    {/*  onClick={() => {*/}
                    {/*    setTabValue(DoAppTabs.SUBSCRIBERS);*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  {t('pages.myOrganization.merchant.details')}*/}
                    {/*</Button>*/}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <AppBar
            position="static"
            color="default"
            elevation={0}
            style={{ marginTop: 20, background: 'none', borderBottom: '1px solid #eee' }}
          >
            <div ref={tabsRef}>
              <Tabs
                value={tabValue}
                onChange={handleDoAppTabsChange}
                indicatorColor="secondary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <StyledTab
                  label={t('pages.myOrganization.merchant.promotions.title')}
                  icon={<LocalOfferOutlinedIcon />}
                  {...a11yProps(DoAppTabs.PROMOTIONS)}
                />
                <StyledTab
                  label={t('pages.myOrganization.merchant.promotionClaims.title')}
                  icon={<PlaylistAddCheckIcon />}
                  {...a11yProps(DoAppTabs.PROMOTION_CLAIMS)}
                />
                <StyledTab
                  label={t('pages.myOrganization.merchant.creditTransactions.title')}
                  icon={<MonetizationOnIcon />}
                  {...a11yProps(DoAppTabs.CREDIT_TRANSACTIONS)}
                  onClick={() => setCreditTransactionsDefaultFilter(undefined)}
                />
                {/*<StyledTab*/}
                {/*  label={t('pages.myOrganization.merchant.subscribers.title')}*/}
                {/*  icon={<PeopleOutlineIcon />}*/}
                {/*  {...a11yProps(DoAppTabs.SUBSCRIBERS)}*/}
                {/*/>*/}
              </Tabs>
            </div>
          </AppBar>
          <TabPanel value={tabValue} index={DoAppTabs.PROMOTIONS}>
            <PromotionsTab
              onClaimClick={(claimStatus, promotionId) => {
                setPromotionClaimStatus(claimStatus);
                setPromotionId(promotionId);
                setTabValue(DoAppTabs.PROMOTION_CLAIMS);
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={DoAppTabs.PROMOTION_CLAIMS}>
            <PromotionClaimsTab
              onClaimDispute={fetchData}
              promotionClaimStatus={promotionClaimStatus}
              promotionId={promotionId}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={DoAppTabs.CREDIT_TRANSACTIONS}>
            <CreditTransactionsTab
              onTransactionRejectionOrWithdrawal={fetchData}
              initialStatusFilter={creditTransactionsDefaultFilter}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={DoAppTabs.SUBSCRIBERS}>
            <SubscribersTab />
          </TabPanel>

          {merchantData && promotionalCreditsConfigurationWindowOpen && (
            <PromotionalCreditsConfigurationWindow
              open={promotionalCreditsConfigurationWindowOpen}
              merchant={merchantData.data}
              onCloseClick={onPromotionalCreditsConfigurationWindowClose}
              fullScreenOnMobile={false}
              onSubmit={(merchant) => {
                setMerchantData({ ...merchantData, data: merchant });
              }}
            />
          )}
          {merchantData && redeemPromotionalCreditsWindowOpen && (
            <RedeemPromotionalCreditsWindow
              open={redeemPromotionalCreditsWindowOpen}
              merchant={merchantData.data}
              onCloseClick={onRedeemPromotionalCreditsWindowClose}
              fullScreenOnMobile
              onSuccessfulRedeem={fetchData}
            />
          )}
          {incentiveCreditsInfoWindowOpen && (
            <IncentiveCreditsInfoWindow
              open={incentiveCreditsInfoWindowOpen}
              onCloseClick={onIncentiveCreditsInfoWindowClose}
              fullScreenOnMobile
            />
          )}
          {merchantRewardsWindowOpen && (
            <MerchantRewardsWindow
              open={merchantRewardsWindowOpen}
              onCloseClick={onMerchantRewardsWindowClose}
              fullScreenOnMobile
              type={rewardType}
            />
          )}
        </>
      )}
    </>
  );
};

export default OrganizationMerchant;

const StyledRedeemButton = styled(Button)`
  margin-left: 10px;
  flex: none;
  &.Mui-disabled {
    background: #e0e0e0;
  }
`;

const StyledTab = styled(Tab)`
  min-height: auto;
  text-transform: none;
  font-size: 1rem;
  & .MuiTab-wrapper {
    flex-direction: row;
    .MuiSvgIcon-root {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
`;
