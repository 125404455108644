import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import ModalFormWindow from '../../../../../Shared/Window/ModalFormWindow';
import { useTranslation } from 'react-i18next';
import Toast from '../../../../../Shared/Toast/Toast';
import app from '../../../../../../config/app/app';
import { FormikProps } from 'formik';
import {
  AdBannerType,
  AdminCreateAdBannerValues,
  HouseAdsBannersCategory
} from '../../../../../../store/AdminAds/types';
import { adminAdsOperations } from '../../../../../../store/AdminAds';
import AdBannerForm from '../../Partials/AdBannerForm';
import moment from 'moment';
import { AdminOrganization } from '../../../../../../store/AdminOrganizations/types';
import isUri from '@stdlib/assert-is-uri';

type AdminCreateAdBannerWindowProps = {
  open: boolean;
  advertiserId: string;
  categories: HouseAdsBannersCategory[];
  organization?: AdminOrganization;
  onCloseClick: () => void;
  onFormSuSubmit?: () => void;
  fullScreenOnMobile: boolean;
  showDates?: boolean;
  showCategories?: boolean;
  showOrganization?: boolean;
  type: AdBannerType;
  adZoneId?: string;
  bannerSize?: number[];
};

const AdminCreateAdBannerWindow: FunctionComponent<AdminCreateAdBannerWindowProps> = ({
  advertiserId,
  onCloseClick,
  organization,
  categories,
  onFormSuSubmit,
  open,
  showDates = true,
  showCategories = true,
  showOrganization = true,
  type,
  adZoneId,
  bannerSize
}) => {
  const { t } = useTranslation();
  const bannerFileRef = useRef<HTMLInputElement | null>(null);
  const bannerFilePreviewRef = useRef<HTMLImageElement | null>(null);
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [bannerFilePreviewUrl, setBannerFilePreviewUrl] = useState<string | null>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (open) {
      setWidth(0);
      setHeight(0);
      setBannerFile(null);
      setBannerFilePreviewUrl(null);
    }
  }, [open]);

  useEffect(() => {
    if (bannerFilePreviewRef.current) {
      bannerFilePreviewRef.current.onload = () => {
        if (bannerFilePreviewRef.current) {
          setWidth(bannerFilePreviewRef.current?.width);
          setHeight(bannerFilePreviewRef.current?.height);
        }

        if (bannerFilePreviewRef.current) {
          bannerFilePreviewRef.current.onload = null;
        }
      };
    }
  }, [bannerFilePreviewUrl]);

  const onSubmit = async (values: AdminCreateAdBannerValues) => {
    try {
      if (bannerFile && advertiserId) {
        const payload = {
          ...values,
          width: width.toString(),
          height: height.toString(),
          bannerFile,
          advertiserId,
          category: values.category
        };
        await adminAdsOperations.createAdBanner(payload, type, { linkToZoneId: adZoneId });

        onCloseClick();
        if (onFormSuSubmit) {
          onFormSuSubmit();
        }
        Toast.success(t('notifications.adminAds.successOperation'));
      }
    } catch (e) {
      Toast.error(t('notifications.adminAds.errorOperation'));
    }
  };

  const onBannerFileChoose = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const extension = file.name.split('.').pop();

      // Check file extension
      if (!app.validLogoExtensions.includes((extension || '').toLowerCase())) {
        Toast.error(t('notifications.uploadLogo.invalidExtension'));
        return;
      }

      // Check file size
      if (file.size > app.maxLogoSize) {
        Toast.error(t('notifications.uploadLogo.fileTooBig'));
        return;
      }

      // Create a temporary image to validate dimensions
      const img = new Image();
      img.onload = () => {
        if (bannerSize) {
          const [adWidth, adHeight] = bannerSize;

          if (img.width !== adWidth || img.height !== adHeight) {
            Toast.error(t('notifications.adminAds.invalidDimensions', { adWidth, adHeight }));
            return;
          }
        }

        // Set file and preview URL after validation
        setBannerFilePreviewUrl(URL.createObjectURL(file));
        setBannerFile(file);
      };

      img.onerror = () => {
        Toast.error(t('notifications.adminAds.errorImageLoad'));
      };

      img.src = URL.createObjectURL(file);
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <AdBannerForm
      bannerFilePreviewUrl={bannerFilePreviewUrl}
      onBannerFileChoose={onBannerFileChoose}
      width={width}
      categories={categories}
      height={height}
      startDate={props.values.startDate}
      endDate={props.values.endDate}
      bannerFileRef={bannerFileRef}
      bannerFilePreviewRef={bannerFilePreviewRef}
      showDates={showDates}
      showCategories={showCategories}
      showOrganization={showOrganization}
    />
  );

  return (
    <ModalFormWindow
      open={open}
      forceDisableSubmit={!bannerFile || !width || !height}
      okButtonText={t('common.create')}
      header={t('pages.adminAds.windows.createAdBanner')}
      onCloseClick={() => {
        setBannerFilePreviewUrl(null);
        onCloseClick();
      }}
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        bannerName: '',
        alt: '',
        url: '',
        comments: '',
        startDate: moment().format(app.dateFormats.inputValueDate),
        endDate: '',
        category: showCategories ? { id: null, label: null } : null,
        organization: organization ? `(${organization.accountNumber || '...'}) ${organization.name}` : 'House Ads'
      }}
      validationSchema={Yup.object().shape({
        bannerName: Yup.string().max(app.maxInputLength).required(),
        alt: Yup.string().max(app.maxInputLength).required(),
        url: Yup.string()
          .max(app.maxInputLength)
          .required()
          .test('validate-uri', t('formValidationErrors.validUri'), (uri: string) => isUri(uri)),
        startDate: Yup.string().required(),
        endDate: Yup.string().nullable(),
        ...(showCategories
          ? {
              category: Yup.object()
                .shape({
                  id: Yup.string(),
                  label: Yup.string()
                })
                .optional(),
              comments: Yup.string()
            }
          : {})
      })}
      maxWidth="md"
    />
  );
};

export default AdminCreateAdBannerWindow;
