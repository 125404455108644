import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../../components/Shared/DataTable/types';

export type DoAppSubscriber = {
  id: string;
  createdAt: Date;
  email: string;
  region: string;
  district: string;
  firstName: string;
  lastName: string;
  deviceId: string;
  credits: number;
};

export type DoAppSubscribersIndexResponse = {
  data: DoAppSubscriber[];
  pagination: Pagination;
};

export type IndexDoAppSubscribersRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export type DoAppMerchantSubscribersState = StateWithPagination &
  StateWithTableSearch<DoAppSubscriber> & {
    data: NormalizedDoAppMerchantSubscribers;
    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export type NormalizedDoAppMerchantSubscribers = {
  entities: Dictionary<DoAppSubscriber>;
  result: string[];
};

export type DoAppMerchantSubscribersIndexActionBEGIN = {
  type: DoAppMerchantSubscribersActionsConstants.INDEX_BEGIN;
};

export type DoAppMerchantSubscribersIndexActionSUCCESS = {
  type: DoAppMerchantSubscribersActionsConstants.INDEX_SUCCESS;
  payload: DoAppSubscribersIndexResponse;
};

export type DoAppMerchantSubscribersIndexActionFAILED = {
  type: DoAppMerchantSubscribersActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export enum DoAppSubscriberVerificationStatus {
  VERIFIED = 'verified',
  EMAIL_CHANGED = 'email-changed',
  EMAIL_CHANGE_WAITING_FOR_SECOND_CONFIRMATION = 'email-change-waiting-for-second-confirmation',
  DEVICE_REGISTERED = 'device-registered'
}

export enum DoAppMerchantSubscribersActionsConstants {
  INDEX_BEGIN = 'DO_APP/MERCHANT_SUBSCRIBERS/INDEX/BEGIN',
  INDEX_SUCCESS = 'DO_APP/MERCHANT_SUBSCRIBERS/INDEX/SUCCESS',
  INDEX_FAILED = 'DO_APP/MERCHANT_SUBSCRIBERS/INDEX/FAILED'
}

export type DoAppMerchantSubscribersActionType =
  | DoAppMerchantSubscribersIndexActionBEGIN
  | DoAppMerchantSubscribersIndexActionSUCCESS
  | DoAppMerchantSubscribersIndexActionFAILED;
